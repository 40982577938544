import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addInputDataAction,
  updateInputDataAction,
} from "../../../common/actions/form.actions";
import { validateInput } from "../../../common/validate.function";

const mapStateToProps = (state, props) => {
  return {
    formData: state.forms[props.formName],
    input:
      state.forms[props.formName] && state.forms[props.formName][props.name]
        ? state.forms[props.formName][props.name]
        : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addInputDataToStore: (payload) => dispatch(addInputDataAction(payload)),
    updateInputDataInStore: (payload) =>
      dispatch(updateInputDataAction(payload)),
  };
};

class FieldInput extends Component {
  componentDidMount() {
    if (!this.props.input.value) {
      this.props.addInputDataToStore({
        formName: this.props.formName,
        inputName: this.props.name,
        inputData: {
          value: this.props.defaultValue,
          validationTypes: this.props.validationTypes,
          errorMessage: null,
        },
      });
    }
  }

  handleChange = (event) => {
    let errorMessage = validateInput(
      event.target.value,
      this.props.validationTypes
    );
    this.props.updateInputDataInStore({
      formName: this.props.formName,
      inputName: this.props.name,
      inputData: {
        value: event.target.value,
        validationTypes: this.props.validationTypes,
        errorMessage: errorMessage ? errorMessage : null,
      },
    });
  };

  render() {
    return (
      <React.Fragment>
        <label
          className={this.props.labelClassName || "standard-input-label"}
          htmlFor={this.props.type}
        >
          {this.props.label}
        </label>
        <textarea
          className={
            this.props.input.errorMessage
              ? this.props.errorClassName || "error-input"
              : this.props.className || "standard-input"
          }
          placeholder={this.props.placeholder}
          name={this.props.name}
          onChange={this.handleChange}
          onBlur={this.handleChange}
          defaultValue={this.props.input.value || this.props.defaultValue}
          rows={this.props.rows_max ? this.props.rows_max : "3"}
          maxLength={this.props.max_length ? this.props.max_length : "150"}
        />
        {this.props.input.errorMessage && (
          <p className={this.props.errorMessageClassName || "error-message"}>
            {this.props.input.errorMessage}
          </p>
        )}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldInput);
