// export const BACKEND_API = "https://localhost:44323";
export const BACKEND_API = "https://commstracker.rs/api"; // production api
export const FRONTEND_API = "https://commstracker.rs";

const year = new Date().getFullYear();
export const years = Array.from(
  new Array(11),
  (val, index) => index - 9 + year
);

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const SHORT_MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const overallColumns = [
  {
    name: "Date",
    selector: "date",
    cell: (row) => {
      let date = new Date(row.date);
      let monthName = SHORT_MONTHS[date.getMonth()];
      let year = date.getFullYear();
      return monthName + " " + year;
    },
    minWidth: "calc (40%-15px)",
    maxWidth: "calc (40%-15px)",
  },
  {
    name: "Total",
    selector: "totalAttendants",
    minWidth: "calc (20%-15px)",
    maxWidth: "calc (20%-15px)",
  },
  {
    name: "Women",
    selector: "femaleAttendants",
    minWidth: "calc (20%-15px)",
    maxWidth: "calc (20%-15px)",
  },
  {
    name: "Men",
    selector: "maleAttendants",
    minWidth: "calc (20%-15px)",
    maxWidth: "calc (20%-15px)",
  },
];

export const beneficiariesColumns = [
  {
    name: "",
    selector: "monthName",
    minWidth: "calc (40%-15px)",
    maxWidth: "calc (40%-15px)",
  },
  {
    name: "Total",
    selector: "totalAttendants",
    minWidth: "calc (20%-15px)",
    maxWidth: "calc (20%-15px)",
  },
  {
    name: "Women",
    selector: "femaleAttendants",
    minWidth: "calc (20%-15px)",
    maxWidth: "calc (20%-15px)",
  },
  {
    name: "Men",
    selector: "maleAttendants",
    minWidth: "calc (20%-15px)",
    maxWidth: "calc (20%-15px)",
  },
];

export const onlineReachColumns = [
  {
    name: "",
    selector: "monthName",
    minWidth: "calc (40%-15px)",
    maxWidth: "calc (40%-15px)",
  },
  {
    name: "Total",
    selector: "totalAttendants",
    minWidth: "calc (20%-15px)",
    maxWidth: "calc (20%-15px)",
  },
];

export const totalsColumns = (year) => {
  return [
    {
      name: year,
      selector: "monthName",
      minWidth: "16.6666%",
      maxWidth: "16.6666%",
      style: {
        color: "events[0].eventColor",
      },
    },
    {
      name: "Direct beneficiaries",
      selector: "events[0].totalAttendants",
      minWidth: "16.6666%",
      maxWidth: "16.6666%",
      style: {
        color: "#4acd56",
      },
    },
    {
      name: "Reached through training",
      selector: "events[1].totalAttendants",
      minWidth: "16.6666%",
      maxWidth: "16.6666%",
      style: {
        color: "#cfc851",
      },
    },
    {
      name: "Reached at conference",
      selector: "events[2].totalAttendants",
      minWidth: "16.6666%",
      maxWidth: "16.6666%",
      style: {
        color: "#5aa2eb",
      },
    },
    {
      name: "Social media reach",
      selector: "events[3].totalAttendants",
      minWidth: "16.6666%",
      maxWidth: "16.6666%",
      style: {
        color: "#c682f4",
      },
    },
    {
      name: "Totals",
      selector: "events[4].totalAttendants",
      minWidth: "16.6666%",
      maxWidth: "16.6666%",
      style: {
        color: "#808080",
      },
    },
  ];
};

export const fourColumns = [
  {
    name: "Date",
    selector: "date",
    cell: (row) => {
      let date = new Date(row.date);
      let monthName = SHORT_MONTHS[date.getMonth()];
      let year = date.getFullYear();
      return monthName + " " + year;
    },
    width: "calc (50% - 12px)",
  },
  {
    name: "Total",
    selector: "totalAttendants",
    width: "calc (50% - 12px)",
  },
];

export const adminsStyles = {
  headRow: {
    style: {
      backgroundColor: "#a4ccf4",
      borderColor: "black",
      height: "40px",
      fontSize: "20px",
    },
  },
  headCells: {
    style: {
      fontSize: "16px",
    },
  },
  rows: {
    style: {
      height: "45px",
      color: "#faa870",
      backgroundColor: "white",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  cells: {
    style: {
      fontSize: "14px",
      fontWeight: "700 !important",
    },
  },
};

export const projectStyles = {
  headRow: {
    style: {
      backgroundColor: "#a4ccf4",
      borderColor: "black",
      height: "40px",
      fontSize: "20px",
    },
  },
  headCells: {
    style: {
      fontSize: "16px",
    },
  },
  rows: {
    style: {
      height: "45px",
      color: "#faa870",
      backgroundColor: "white",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  cells: {
    style: {
      fontSize: "14px",
      fontWeight: "700 !important",
    },
  },
};

export const eventStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  cells: {
    style: {
      justifyContent: "center",
    },
  },
  headCells: {
    style: {
      justifyContent: "center",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#a4ccf4",
      borderColor: "black",
      height: "30px",
    },
  },
};

export const totalsStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  cells: {
    style: {
      justifyContent: "center",
    },
  },
  headCells: {
    style: {
      justifyContent: "center",
    },
  },
  headRow: {
    style: {
      height: "30px",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#a4ccf4",
      borderColor: "black",
      height: "45px",
    },
  },
  // headCells: {
  //   style: {
  //     justifyContent: "center",
  //     textAlign: "center",
  //     "&:nth-child(2)": {
  //       backgroundColor: "#4acd56",
  //     },
  //     "&:nth-child(3)": {
  //       backgroundColor: "#cfc851",
  //     },
  //     "&:nth-child(4)": {
  //       backgroundColor: "#5aa2eb",
  //     },
  //     "&:nth-child(5)": {
  //       backgroundColor: "#c682f4",
  //     },
  //     "&:nth-child(6)": {
  //       backgroundColor: "#808080",
  //     },
  //   },
  // },
};

export const eventRowStyles = [
  {
    when: (row) => row.eventTypeId === 1,
    style: {
      color: "#4acd56",
      backgroundColor: "white",
      "&:hover": {
        cursor: "pointer",
        color: "#fd686f",
      },
    },
  },
  {
    when: (row) => row.eventTypeId === 2,
    style: {
      color: "#cfc851",
      backgroundColor: "white",
      "&:hover": {
        cursor: "pointer",
        color: "#fd686f",
      },
    },
  },
  {
    when: (row) => row.eventTypeId === 3,
    style: {
      color: "#5aa2eb",
      backgroundColor: "white",
      "&:hover": {
        cursor: "pointer",
        color: "#fd686f",
      },
    },
  },
  {
    when: (row) => row.eventTypeId === 4,
    style: {
      color: "#c682f4",
      backgroundColor: "white",
      "&:hover": {
        cursor: "pointer",
        color: "#fd686f",
      },
    },
  },
];
