import React, { Component } from "react";
import NavigationMenu from "./navigation.menu";

export default class Navigation extends Component {
  render() {
    return (
      <React.Fragment>
        <NavigationMenu />
      </React.Fragment>
    );
  }
}
