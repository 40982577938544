import axios from "axios";
import { BACKEND_API } from "../../common/consts";
import { toast } from "react-toastify";
import { handleErrorStatusCode } from "../utility.functions";

export function loginApiCall(data) {
  return axios
    .post(
      BACKEND_API + "/api/identity/token",
      {
        email: data.email,
        password: data.password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .catch((error) => {
      handleErrorStatusCode(error.response.status);
    })
    .then((response) => {
      if (response.data.succeeded) {
        // toast.success(response.data.message, {
        //   containerId: "Success",
        // });
        return response;
      } else {
        toast.error(response.data.message, {
          containerId: "Error",
        });
        return response;
      }
    });
}

export function confirmEmail(params) {
  return (dispatch) => {
    return axios
      .get(
        BACKEND_API +
        "/api/identity/confirm-email/?userId=" +
        params.userId +
        "&code=" +
        params.code,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        handleErrorStatusCode(error.response.status);
      })
      .then((response) => {
        if (response.data.succeeded) {
          toast.success(response.data.message, {
            containerId: "Success",
          });
          return response;
        } else {
          toast.error(response.data.message, {
            containerId: "Error",
          });
          return response;
        }
      });
  };
}

export function setPassword(data) {
  return (dispatch) => {
    return axios
      .post(
        BACKEND_API + "/api/identity/set-password",
        {
          email: data.email,
          token: data.token,
          password: data.password,
          confirmPassword: data.confirmPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        handleErrorStatusCode(error.response.status);
      })
      .then((response) => {
        if (response.data.succeeded) {
          toast.success(response.data.message, {
            containerId: "Success",
          });
          return response;
        } else {
          toast.error(response.data.message, {
            containerId: "Error",
          });
          return response;
        }
      });
  };
}

export function forgotPassword(email) {
  return (dispatch) => {
    return axios
      .post(
        BACKEND_API + "/api/identity/forgot-password",
        {
          email: email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        handleErrorStatusCode(error.response.status);
      })
      .then((response) => {
        if (response.data.succeeded) {
          toast.success(response.data.message, {
            containerId: "Success",
          });
          return response;
        } else {
          toast.error(response.data.message, {
            containerId: "Error",
          });
          return response;
        }
      });
  };
}
