import React from "react";
import OverviewTable from "../../components/table/overviewTable";

import {
  beneficiariesColumns,
  eventStyles,
  eventRowStyles,
} from "../../common/consts";

const DirectBeneficiaries = () => {
  return (
    <OverviewTable
      type={1}
      columns={beneficiariesColumns}
      customStyles={eventStyles}
      conditionalRowStyles={eventRowStyles}
      title="Direct beneficiaries"
      headingClass="totals"
      selectColor="#faa870"
    />
  );
};

export default DirectBeneficiaries;
