import { createFormAction, clearFormDataAction } from "./actions/form.actions";

export function handleErrorStatusCode(statusCode) {
  switch (statusCode) {
    case 401: {
      window.location.href = "/expired";
      break;
    }
    case 404: {
      window.location.href = "/notFound";
      break;
    }
    case 500: {
      window.location.href = "/error";
      break;
    }
    default: {
    }
  }
}

export function createForm(form, dispatch, formName) {
  if (!form) {
    dispatch(createFormAction({ formName: formName }));
  }
}

export function clearForm(form, dispatch, formName) {
  dispatch(clearFormDataAction({ formData: form, formName: formName }));
}

export function repackingFormDataIntoJson(rawData) {
  let Json = {};
  for (let form in rawData) {
    if (Array.isArray(rawData[form])) {
      Json[form] = [];
      rawData[form].forEach((item) => {
        let repackedForm = {};
        for (let property in item) {
          repackedForm[property] = extractValueFromForm(item[property]);
        }
        Json[form].push(repackedForm);
      });
    } else {
      for (var formProperty in rawData[form]) {
        if (Array.isArray(rawData[form][formProperty])) {
          Json[formProperty] = listingFormPropertyValue(
            rawData[form][formProperty]
          );
        } else {
          if (Array.isArray(rawData[form][formProperty].value)) {
            Json[formProperty] = listingFormPropertyValue(
              rawData[form][formProperty].value
            );
          } else {
            Json[formProperty] = extractValueFromForm(
              rawData[form][formProperty]
            );
          }
        }
      }
    }
  }
  return Json;
}

function extractValueFromForm(data) {
  if (data.value) {
    if (data.value.value) {
      return formatInputValue(data.value.value);
    } else return formatInputValue(data.value);
  } else {
    return formatInputValue(data.value);
  }
}

function formatInputValue(value) {
  if (
    value &&
    typeof value.getMonth === "function" &&
    typeof value.getFullYear === "function"
  ) {
    let reformedDate = value.toDateString();
    return reformedDate;
  } else if (!value) {
    return null;
  } else return value;
}

export function getDefaultValueForMultiValueSelectInput(options, values) {
  if (values && values.length) {
    return getMultiSelectInputDefaultValue(options, values);
  }
  return [];
}

export function getMultiSelectInputDefaultValue(options, values) {
  let resultArray = [];
  values.forEach((value) => {
    if (value.value) {
      resultArray.push(
        getSelectInputSelectedOptionByValue(options, value.value)
      );
    } else {
      resultArray.push(getSelectInputSelectedOptionByValue(options, value));
    }
  });
  return resultArray;
}

export function getSelectInputSelectedOptionByValue(options, value) {
  if (value.label) {
    return {
      label: options.find((element) => element.value === value.label)
        ? options.find((element) => element.value === value.label).label
        : value.label,
      value: value.label,
      verified: value.verified,
      deleted: value.deleted,
    };
  } else {
    return {
      label: options.find((element) => element.value === value)
        ? options.find((element) => element.value === value).label
        : value,
      value: value,
    };
  }
}

export function addingParamToObjectsInArray(list, newParamName, oldParamName) {
  let resultArray = [];
  if (!(list[0] && list[0][newParamName])) {
    list.forEach((element) => {
      element[newParamName] = element[oldParamName];
      resultArray.push(element);
    });
    return resultArray;
  } else return list;
}

export function listingFormPropertyId(data) {
  let result = [];
  data.forEach((item) => {
    result.push(item.id);
  });
  return result;
}

export function listingFormPropertyValue(data) {
  let result = [];
  data.forEach((item) => {
    result.push(item.value);
  });
  return result;
}

export function mapProjectsToSelectOptions(arr) {
  return arr.map((e) => {
    return { value: e.id, label: e.name };
  });
}

export function mapYearsToSelectOptions(arr) {
  return arr.map((e) => {
    return { value: e, label: e };
  });
}
