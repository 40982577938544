import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createForm } from "../../common/utility.functions";
import { checkErrorsAction } from "../../common/actions/form.actions";
import {
  createModalAction,
  toggleModalAction,
} from "../../common/actions/modal.actions";
import CreateAdminModal from "../../components/modals/admin-modals/create-admin-modal";
import EditAdmindModal from "../../components/modals/admin-modals/edit-admin-modal";
import { BasicTable } from "../../components/table/table";
import { adminsStyles } from "../../common/consts";
import { getAdmins, getAdmin, editAdmin, deleteAdmin } from "../../common/api/admins.api";
import { resendEmailApi } from "../../common/api/utility.api";
import DeleteModal from "../../components/modals/delete-modal"

const FORM_NAME = "admins";
const CREATE_ADMIN_MODAL = "createAdminModal";
const EDIT_ADMIN_MODAL = "editAdminModal";
const DELETE_ADMIN_MODAL = "deleteAdminModal";

const mapStateToProps = (state) => {
  return {
    formData: state.forms[FORM_NAME],
    editFormData: state.forms[EDIT_ADMIN_MODAL],
    showCreateAdminModal:
      state.modals && state.modals[CREATE_ADMIN_MODAL]
        ? state.modals[CREATE_ADMIN_MODAL]
        : false,
    showEditAdminModal:
      state.modals && state.modals[EDIT_ADMIN_MODAL]
        ? state.modals[EDIT_ADMIN_MODAL]
        : false,
    showDeleteAdminModal:
      state.modals && state.modals[DELETE_ADMIN_MODAL]
        ? state.modals[DELETE_ADMIN_MODAL]
        : false,
    tableData: state.admins && state.admins.length > 0 ? state.admins : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createForm: (formData, formName) =>
      createForm(formData, dispatch, formName),
    sendErrors: (formData) =>
      dispatch(checkErrorsAction({ formName: FORM_NAME, formData })),
    createModal: (modalName) => dispatch(createModalAction({ modalName })),
    toggleModal: (modalName) => dispatch(toggleModalAction({ modalName })),
    getAdmins: () => {
      dispatch(getAdmins());
    },
    getAdmin: (id, data) => {
      dispatch(getAdmin(id, data));
    },
    editAdmin: (id, name, toggleBlocking) => {
      dispatch(editAdmin(id, name, toggleBlocking));
    },
    deleteAdmin: (id, toggleBlocking) => {
      dispatch(deleteAdmin(id, toggleBlocking));
    },
    resendEmail: (email) => {
      dispatch(resendEmailApi(email));
    },
  };
};

class Admins extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.props.createForm(this.props.formData, FORM_NAME);
    this.props.createForm(this.props.formData, EDIT_ADMIN_MODAL);
    this.props.createModal(CREATE_ADMIN_MODAL);
    this.props.createModal(EDIT_ADMIN_MODAL);
    this.props.createModal(DELETE_ADMIN_MODAL);
    this.props.getAdmins();
  }

  columns = [
    {
      name: "Admin Name",
      // selector: "name",
      width: "60% !important",
      cell: (row) => <div title={row.email}>{row.name}</div>,
    },
    {
      actions: true,
      width: "15% !important",
      cell: (row) => (
        <button
          onClick={() => {
            this.onResend(row);
          }}
          className={"small-button"}
        >
          Resend
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      actions: true,
      width: "10% !important",
      cell: (row) => (
        <button
          onClick={() => {
            this.onEdit(row);
          }}
          className={"small-button"}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      actions: true,
      width: "15% !important",
      cell: (row) => (
        <button
          onClick={() => {
            this.onDelete(row);
          }}
          className={"small-button"}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  openModal = (name) => {
    this.props.toggleModal(name);
  };

  executeEdit = (data) => {
    this.props.editAdmin(data.id, data.name, this.props.toggleBlockUI);
  };

  executeDelete = () => {
    this.props.deleteAdmin(this.state.id, this.props.toggleBlockUI);
    this.closeModal(DELETE_ADMIN_MODAL)
  }

  closeModal = (name) => {
    this.props.toggleModal(name);
    this.setState({});
  };

  onEdit = (row) => {
    this.setState({ id: row.id });
    this.openModal(EDIT_ADMIN_MODAL);
    this.props.getAdmin(row.id, this.props.editFormData);
  };

  onDelete = (row) => {
    this.setState({ id: row.id });
    this.openModal(DELETE_ADMIN_MODAL);
  };

  onResend = (row) => {
    this.props.resendEmail(row.email);
  };

  render() {
    return (
      <div className="full-page-body">
        <div className="body">
          <div className="card-container">
            <div className="headline-container">
              <div className="headline-title">Administrators</div>
              <button
                className="small-button"
                onClick={() => {
                  this.openModal(CREATE_ADMIN_MODAL);
                }}
              >
                Add new
              </button>
            </div>
            <CreateAdminModal
              formName={FORM_NAME}
              modalName={CREATE_ADMIN_MODAL}
              showModal={this.props.showCreateAdminModal}
              onClose={() => {
                this.closeModal(CREATE_ADMIN_MODAL);
              }}
              blockState={this.props.blockState}
              toggleBlockUI={this.props.toggleBlockUI}
            />
            <EditAdmindModal
              adminId={this.state.id}
              formName={EDIT_ADMIN_MODAL}
              modalName={EDIT_ADMIN_MODAL}
              showModal={this.props.showEditAdminModal}
              onConfirm={this.executeEdit}
              onClose={() => {
                this.closeModal(EDIT_ADMIN_MODAL);
              }}
              blockState={this.props.blockState}
            />
            <div className="table-container">
              <BasicTable
                customStyles={adminsStyles}
                columns={this.columns}
                data={this.props.tableData}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
              />
            </div>
            <DeleteModal
              modalTitle={"Confirm Admin removal"}
              modalBody={"If you confirm, this Admin will no longer be able to access the aplication, but their data will remain intact."}
              showModal={this.props.showDeleteAdminModal}
              onConfirm={this.executeDelete}
              onClose={() => {
                this.closeModal(DELETE_ADMIN_MODAL);
              }}
              blockState={this.props.blockState}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admins));
