import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SelectInput from "../../components/inputs/select-input/select-input";
import { ALL_EVENT_OPTIONS_COLORED } from "../../components/inputs/select-input/select-input-consts";
import { createForm } from "../../common/utility.functions";
import {
  createModalAction,
  toggleModalAction,
} from "../../common/actions/modal.actions";
import "../../components/modals/modals.scss";
import CreateEventModal from "../../components/modals/event-modals/create-event-modal";
import { BasicTable } from "../../components/table/table";
import {
  overallColumns,
  fourColumns,
  eventStyles,
  eventRowStyles,
} from "../../common/consts";
import { getProjectsForClient } from "../../common/api/projects.api";
import {
  getEvents,
  getEvent,
  addEvent,
  editEvent,
  deleteEvent,
} from "../../common/api/events.api";
import DeleteModal from "../../components/modals/delete-modal";
import EditEventModal from "../../components/modals/event-modals/edit-client-modal";

const FORM_NAME = "clientEvents";
const CREATE_EVENT_MODAL = "createEventModal";
const EDIT_EVENT_MODAL = "editEventModal";
const DELETE_EVENT_MODAL = "deleteEventModal";
const CLIENT_DATA = "clientData";

const mapStateToProps = (state) => {
  return {
    formData: state.forms[FORM_NAME],
    clientData: state.forms[CLIENT_DATA],
    editModalData: state.forms[EDIT_EVENT_MODAL],
    showCreateEventModal:
      state.modals && state.modals[CREATE_EVENT_MODAL]
        ? state.modals[CREATE_EVENT_MODAL]
        : false,
    showEditEventModal:
      state.modals && state.modals[EDIT_EVENT_MODAL]
        ? state.modals[EDIT_EVENT_MODAL]
        : false,
    showDeleteEventModal:
      state.modals && state.modals[DELETE_EVENT_MODAL]
        ? state.modals[DELETE_EVENT_MODAL]
        : false,
    projects: state.projects ? state.projects : [],
    events: state.events ? state.events : [],
    currentEventType:
      state.forms[CLIENT_DATA] &&
      state.forms[CLIENT_DATA].currentEventType &&
      state.forms[CLIENT_DATA].currentEventType.value
        ? state.forms[CLIENT_DATA].currentEventType.value
        : null,
    currentClientProject:
      state.forms[CLIENT_DATA] &&
      state.forms[CLIENT_DATA].currentClientProject &&
      state.forms[CLIENT_DATA].currentClientProject.value
        ? state.forms[CLIENT_DATA].currentClientProject.value
        : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createForm: (formData, formName) =>
      createForm(formData, dispatch, formName),
    createModal: (modalName) => dispatch(createModalAction({ modalName })),
    toggleModal: (modalName) => dispatch(toggleModalAction({ modalName })),
    getProjects: () => {
      dispatch(getProjectsForClient());
    },
    getEvents: (url) => {
      dispatch(getEvents(url));
    },
    getEvent: (id, data) => {
      dispatch(getEvent(id, data));
    },
    addEvent: (data, url, toggleBlocking) => {
      dispatch(addEvent(data, url, toggleBlocking));
    },
    editEvent: (data, url, toggleBlocking) => {
      dispatch(editEvent(data, url, toggleBlocking));
    },
    deleteEvent: (data, url, toggleBlocking) => {
      dispatch(deleteEvent(data, url, toggleBlocking));
    },
  };
};

class ClientEvents extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.props.createForm(this.props.formData, FORM_NAME);
    this.props.createForm(this.props.clientData, CLIENT_DATA);
    this.props.createForm(this.props.formData, CREATE_EVENT_MODAL);
    this.props.createForm(this.props.formData, EDIT_EVENT_MODAL);
    this.props.createModal(CREATE_EVENT_MODAL);
    this.props.createModal(EDIT_EVENT_MODAL);
    this.props.createModal(DELETE_EVENT_MODAL);
  }

  componentDidMount() {
    this.props.getProjects();
    this.getTableData(this.getEventUrl());
  }

  getEventUrl = () => {
    let url = "";
    if (this.props.currentClientProject) {
      url = "/?projectId=" + this.props.currentClientProject;
      if (this.props.currentEventType) {
        url = url + "&eventTypeId=" + this.props.currentEventType;
      }
    } else if (this.props.currentEventType) {
      url = url + "/?eventTypeId=" + this.props.currentEventType;
    }
    return url;
  };

  onProjectChange = (selectedOption = null) => {
    let url = "";
    if (selectedOption) {
      url = "/?projectId=" + selectedOption;
      if (this.props.currentEventType) {
        url = url + "&eventTypeId=" + this.props.currentEventType;
      }
    } else if (this.props.currentEventType) {
      url = url + "/?eventTypeId=" + this.props.currentEventType;
    }
    this.getTableData(url);
  };

  onEventChange = (selectedOption = null) => {
    let url = "";
    if (selectedOption) {
      url = "/?eventTypeId=" + selectedOption;
      if (this.props.currentClientProject) {
        url = url + "&projectId=" + this.props.currentClientProject;
      }
    } else if (this.props.currentClientProject) {
      url = url + "/?projectId=" + this.props.currentClientProject;
    }
    this.getTableData(url);
  };

  getTableData = (url) => {
    this.props.getEvents(url);
  };

  openModal = (name) => {
    this.props.toggleModal(name);
  };

  closeModal = (name) => {
    this.props.toggleModal(name);
    this.setState({});
  };

  addNewClient = (client) => {
    let url = this.getEventUrl();
    this.props.addEvent(client, url, this.props.toggleBlockUI);
    // toast.success("New Action added!", {
    //   containerId: "Success",
    // });
  };

  onEditExpand = (data) => {
    this.setState({ eventId: data.id, eventTypeId: data.eventTypeId });
    this.props.getEvent(data.id, this.props.editModalData);
    this.openModal(EDIT_EVENT_MODAL);
  };

  executeEdit = (client) => {
    let url = this.getEventUrl();
    this.props.editEvent(client, url, this.props.toggleBlockUI);
    // toast.success("Edit Action successful!", {
    //   containerId: "Success",
    // });
  };

  onDeleteExpand = (data) => {
    this.setState({ eventId: data.id });
    this.openModal(DELETE_EVENT_MODAL);
  };

  deleteEvent = () => {
    let url = this.getEventUrl();
    let eventId = this.state.eventId;
    this.props.deleteEvent(eventId, url, this.props.toggleBlockUI);
    this.setState({});
    this.closeModal(DELETE_EVENT_MODAL);
  };

  findProject = (id) => {
    let properProject = { value: 0, label: "All" };
    this.props.projects.forEach((project) => {
      if (project.id === id) {
        properProject = project;
      }
    });
    return properProject;
  };

  checkProjects = () => {
    if (this.props.projects.length > 1) {
      var projectList = [{ value: 0, label: "All" }];
      this.props.projects.forEach((project) => {
        projectList.push({ value: project.id, label: project.name });
      });
      return (
        <SelectInput
          className="react-select-container  margin-bot-20"
          labelClassName="modal-input-label"
          options={projectList}
          isMulti={false}
          name={"currentClientProject"}
          formName={FORM_NAME}
          label={""}
          validationTypes={[]}
          placeholder="Project list"
          showAllSelected={false}
          defaultValue={this.findProject(this.props.currentClientProject)}
          errorClassName="modal-error-select-input"
          errorMessageClassName="modal-error-message"
          onChange={this.onProjectChange}
          secondForm={CLIENT_DATA}
        />
      );
    } else {
      return <div className="blank-space-holder"></div>;
    }
  };

  render() {
    return (
      <div className="full-page-body">
        <div className="body">
          <div className="card-container">
            <div className="headline-container">
              <div className="headline-half">
                <div className="headline-title">Actions</div>
                <button
                  className="small-button"
                  onClick={() => {
                    this.openModal(CREATE_EVENT_MODAL);
                  }}
                >
                  New Action
                </button>
              </div>
              <div className="headline-half headline-half-big">
                {this.checkProjects()}
                <SelectInput
                  className="react-select-container"
                  labelClassName="modal-input-label"
                  options={ALL_EVENT_OPTIONS_COLORED}
                  isMulti={false}
                  name={"currentEventType"}
                  formName={FORM_NAME}
                  label={""}
                  validationTypes={[]}
                  placeholder="Action Type"
                  showAllSelected={false}
                  coloredDot={true}
                  defaultValue={[]}
                  errorClassName="modal-error-select-input"
                  errorMessageClassName="modal-error-message"
                  onChange={this.onEventChange}
                  secondForm={CLIENT_DATA}
                />
              </div>
            </div>
            <div className="table-container">
              <BasicTable
                columns={
                  this.props.currentEventType === "4"
                    ? fourColumns
                    : overallColumns
                }
                data={this.props.events}
                customStyles={eventStyles}
                conditionalRowStyles={eventRowStyles}
                expandableRows={true}
                expandOnRowClicked={true}
                expandableRowsComponent={true}
                expandableComponent={"events"}
                onEditExpand={this.onEditExpand}
                onDeleteExpand={this.onDeleteExpand}
              />
            </div>
            <CreateEventModal
              formName={CREATE_EVENT_MODAL}
              modalName={CREATE_EVENT_MODAL}
              showModal={this.props.showCreateEventModal}
              onConfirm={this.addNewClient}
              onClose={() => {
                this.closeModal(CREATE_EVENT_MODAL);
              }}
              blockState={this.props.blockState}
            />
            <EditEventModal
              eventId={this.state.eventId}
              eventTypeId={this.state.eventTypeId}
              formName={EDIT_EVENT_MODAL}
              modalName={EDIT_EVENT_MODAL}
              showModal={this.props.showEditEventModal}
              onConfirm={this.executeEdit}
              onClose={() => {
                this.closeModal(EDIT_EVENT_MODAL);
              }}
              blockState={this.props.blockState}
            />
            <DeleteModal
              modalTitle={"Event removal"}
              modalBody={"Are you sure you want to delete this event?"}
              showModal={this.props.showDeleteEventModal}
              onConfirm={this.deleteEvent}
              onClose={() => {
                this.closeModal(DELETE_EVENT_MODAL);
              }}
              blockState={this.props.blockState}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientEvents)
);
