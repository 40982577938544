import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createForm } from "../../common/utility.functions";
import { getProjectsForClient } from "../../common/api/projects.api";
import { addInputDataAction } from "../../common/actions/form.actions";

const FORM_NAME = "clientData";

const mapStateToProps = (state) => {
  return {
    projects: state.projects ? state.projects : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createForm: (formData) => createForm(formData, dispatch, FORM_NAME),
    addInputDataToStore: (payload) => dispatch(addInputDataAction(payload)),
    getProjects: () => {
      dispatch(getProjectsForClient());
    },
  };
};

class ClientProjects extends Component {
  constructor(props) {
    super(props);
    this.props.createForm(this.props.formData);
    this.props.getProjects();
  }

  openProject = (project) => {
    this.props.addInputDataToStore({
      formName: FORM_NAME,
      inputName: "currentClientProject",
      inputData: { value: project.id },
    });
    this.props.history.push("/events");
  };

  render() {
    return (
      <div className="full-page-body">
        <div className="body">
          <div className="card-container project-padding-bot">
            <div className="login-headline">Choose Project</div>
            {this.props.projects.length > 0 &&
              this.props.projects.map((project, index) => {
                return (
                  <button
                    key={index}
                    className="login-button"
                    onClick={() => {
                      this.openProject(project);
                    }}
                  >
                    {project.name}
                  </button>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientProjects)
);
