import React from "react";
import { Route } from "react-router-dom";
import logo from "../images/logo.jpg";
import generation from "../images/generation.png";
import Navigation from "../components/navigation/navigation";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";

function logout() {
  localStorage.clear();
  window.location.href = "/";
}

export const Rerouter = ({ component: Component, ...properties }) => {
  return (
    <Route
      {...properties}
      render={(props) => {
        console.log(properties);
        return (
          <BlockUi
            className={"loader"}
            message={"L O A D I N G"}
            tag="div"
            blocking={properties.blockState}
          >
            <div id="outer-container">
              <div className="navbar-container">
                {properties.sideNav ? <Navigation></Navigation> : null}
                <img
                  className="logo-picture"
                  src={logo}
                  alt="Logo"
                  height="40"
                />
                <div className="logout-button" onClick={logout}>
                  Sign Out
                </div>
              </div>
              <img id="generation-picture" src={generation} />
              <div id="page-wrap" className="page-below-navigation">
                <Component
                  toggleBlockUI={
                    properties.toggleBlockUI ? properties.toggleBlockUI : null
                  }
                ></Component>
              </div>
            </div>
          </BlockUi>
        );

        // if (Authentication.isAuthenticated()) {
        //    if (!localStorage.getItem("techieFarmTokenRefreshIsActive")) {
        //       localStorage.setItem("techieFarmTokenRefreshIsActive", true);
        //       tokenRefresher();
        //    }
        // }
        // if (itterateClearances(allowedTo)) {
        //    return <Component {...props} />;
        // } else {
        //    return <Redirect to={{ pathname: rerouteLocation() }} />;
        // }
      }}
    />
  );
};
