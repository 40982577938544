import chroma from "chroma-js";

export const dot = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

export const COLOR_STYLES = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? data.color
          : isFocused
            ? color.alpha(0.1).css()
            : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

export const EVENT_OPTIONS = [
  { value: "directBeneficaries", label: "Direct Beneficaries" },
  { value: "capacityDevelopment", label: "Reached through training" },
  { value: "reach", label: "Reached at conference" },
  { value: "onlineReach", label: "Social media reach" },
];

export const EVENT_OPTIONS_COLORED = [
  {
    value: "1",
    label: "Direct Beneficaries",
    color: "#4acd56",
  },
  {
    value: "2",
    label: "Reached through training",
    color: "#cfc851",
  },
  { value: "3", label: "Reached at conference", color: "#5aa2eb" },
  { value: "4", label: "Social media reach", color: "#c682f4" },
];

export const ALL_EVENT_OPTIONS_COLORED = [
  { value: 0, label: "All", color: "#acacac" },
  {
    value: "1",
    label: "Direct Beneficaries",
    color: "#4acd56",
  },
  {
    value: "2",
    label: "Reached through training",
    color: "#cfc851",
  },
  { value: "3", label: "Reached at conference", color: "#5aa2eb" },
  { value: "4", label: "Social media reach", color: "#c682f4" },
];
