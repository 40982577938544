export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENTS_FOR_ADMIN = "GET_EVENTS_FOR_ADMIN";
export const GET_TOTAL_EVENTS_FOR_ADMIN = "GET_TOTAL_EVENTS_FOR_ADMIN";

export const getEventsAction = (payload) => {
  return {
    type: GET_EVENTS,
    payload,
  };
};

export const getEventsForAdminAction = (payload) => {
  return {
    type: GET_EVENTS_FOR_ADMIN,
    payload,
  };
};

export const getTotalEventsForAdminAction = (payload) => {
  return {
    type: GET_TOTAL_EVENTS_FOR_ADMIN,
    payload,
  };
};
