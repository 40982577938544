import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createForm } from "../../common/utility.functions";
import { getAdminDashboard } from "../../common/api/admins.api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardCheck,
  faGlobeAmericas,
  faUsers,
  faUserCheck,
  faChalkboardTeacher,
} from "@fortawesome/free-solid-svg-icons";

const FORM_NAME = "overview";

const mapStateToProps = (state) => {
  return {
    formData: state.forms[FORM_NAME],
    directBeneficiaries:
      state.forms[FORM_NAME] &&
        state.forms[FORM_NAME][0] &&
        state.forms[FORM_NAME][0].subtitle
        ? state.forms[FORM_NAME][0].subtitle
        : null,
    capacityDevelopment:
      state.forms[FORM_NAME] &&
        state.forms[FORM_NAME][1] &&
        state.forms[FORM_NAME][1].subtitle
        ? state.forms[FORM_NAME][1].subtitle
        : null,
    reach:
      state.forms[FORM_NAME] &&
        state.forms[FORM_NAME][2] &&
        state.forms[FORM_NAME][2].subtitle
        ? state.forms[FORM_NAME][2].subtitle
        : null,
    onlineReach:
      state.forms[FORM_NAME] &&
        state.forms[FORM_NAME][3] &&
        state.forms[FORM_NAME][3].subtitle
        ? state.forms[FORM_NAME][3].subtitle
        : null,
    totalReach:
      state.forms[FORM_NAME] &&
        state.forms[FORM_NAME][4] &&
        state.forms[FORM_NAME][4].subtitle
        ? state.forms[FORM_NAME][4].subtitle
        : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createForm: (formData, formName = FORM_NAME) =>
      createForm(formData, dispatch, formName),
    getAdminDashboard: () => {
      dispatch(getAdminDashboard(FORM_NAME));
    },
  };
};

class AdminOverview extends Component {
  constructor(props) {
    super(props);
    this.props.createForm(this.props.formData);
    this.props.getAdminDashboard();
  }

  render() {
    return (
      <div className="body">
        <div className="card-container">
          <div className="reach-container">
            <div className="reach-component">
              <FontAwesomeIcon
                className="reach-icon"
                size="3x"
                icon={faUserCheck}
              />
              <div
                onClick={() => this.props.history.push("/directbeneficiaries")}
                className="reach-text-container"
              >
                <div className="reach-number">
                  {this.props.directBeneficiaries}
                </div>
                Direct beneficiaries
              </div>
            </div>
            <div className="reach-component">
              <FontAwesomeIcon
                className="reach-icon"
                size="3x"
                icon={faChalkboardTeacher}
              />
              <div
                onClick={() => this.props.history.push("/capacitydevelopment")}
                className="reach-text-container"
              >
                <div className="reach-number">
                  {this.props.capacityDevelopment}
                </div>
                Reached through training
              </div>
            </div>
            <div className="reach-component">
              <FontAwesomeIcon
                className="reach-icon"
                size="3x"
                icon={faUsers}
              />
              <div
                onClick={() => this.props.history.push("/reach")}
                className="reach-text-container"
              >
                <div className="reach-number">{this.props.reach}</div>Reached at
                conference
              </div>
            </div>
            <div className="reach-component">
              <FontAwesomeIcon
                className="reach-icon"
                size="3x"
                icon={faGlobeAmericas}
              />
              <div
                onClick={() => this.props.history.push("/onlinereach")}
                className="reach-text-container"
              >
                <div className="reach-number">{this.props.onlineReach}</div>
                Social media reach
              </div>
            </div>
            <div className="reach-component">
              <FontAwesomeIcon
                className="reach-icon"
                size="3x"
                icon={faClipboardCheck}
              />
              <div
                onClick={() => this.props.history.push("/totals")}
                className="reach-text-container"
              >
                <div className="reach-number"> {this.props.totalReach} </div>
                Total Reach
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminOverview)
);
