import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Input from "../../inputs/input/input";
import {
  checkErrorsAction,
  deleteInputDataAction,
} from "../../../common/actions/form.actions";
import { validateForm } from "../../../common/validate.function";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";

const mapStateToProps = (state, props) => {
  return {
    formData: state.forms[props.formName],
    editProjectName:
      state.forms[props.formName] &&
      state.forms[props.formName]["editProjectName"]
        ? state.forms[props.formName]["editProjectName"].value
        : {},
    projects: state.projects ? state.projects : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendErrors: (formName, formData) =>
      dispatch(checkErrorsAction({ formName, formData })),
    deleteInputData: (payload) => dispatch(deleteInputDataAction(payload)),
  };
};

class EditProjectModal extends Component {
  onConfirm = () => {
    const result = validateForm(this.props.formData);
    if (result.isValid) {
      this.props.onConfirm({
        id: this.props.projectId,
        name: this.props.editProjectName,
        code: this.props.editProjectName.toUpperCase(),
      });
      this.onClose();
    } else {
      this.props.sendErrors(this.props.formName, result.formData);
    }
  };

  onClose = () => {
    this.props.deleteInputData({
      formName: this.props.formName,
      inputName: "editProjectName",
    });
    this.props.onClose();
  };

  render() {
    return (
      <Modal
        show={this.props.showModal}
        onHide={this.onClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <BlockUi
          className={"loader"}
          message={"L O A D I N G"}
          tag="div"
          blocking={this.props.blockState}
        >
          <Modal.Header closeButton>
            <Modal.Title id="modal-title">Edit project</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Input
              labelClassName="modal-input-label"
              className="modal-input"
              type="text"
              label="Name"
              placeholder="Write new project's name"
              name={"editProjectName"}
              validationTypes={["required"]}
              formName={this.props.formName}
              errorClassName="modal-error-input"
              errorMessageClassName="modal-error-message"
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-button-container">
              <button
                className="modal-button-confirm modal-button"
                onClick={this.onConfirm}
              >
                CONFIRM
              </button>
              <button
                className="modal-button-cancel modal-button"
                onClick={this.onClose}
              >
                CANCEL
              </button>
            </div>
          </Modal.Footer>
        </BlockUi>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProjectModal);
