import {
  GET_ADMINS,
  GET_ADMIN_BY_ID,
  GET_AVALIBLE_YEARS,
} from "../actions/admin.actions";

const initialState = [];

const adminsReducer = (state = initialState, action) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_ADMINS: {
      if (action.payload) newState = action.payload;
      return newState;
    }

    case GET_ADMIN_BY_ID: {
      return newState;
    }

    case GET_AVALIBLE_YEARS: {
      if (action.payload) newState = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

export default adminsReducer;
