import { get, post, put, remove } from "./controller.api";
import { getClientsAction } from "../../common/actions/client.actions";
import { getSingleAction } from "../../common/actions/form.actions";
import { addingParamToObjectsInArray } from "../utility.functions";

export function getClient(id, editFormData) {
  return (dispatch) => {
    return get("/api/clients/" + id).then((response) => {
      let projects = addingParamToObjectsInArray(
        response.data.data.projects,
        "label",
        "name"
      );
      projects = addingParamToObjectsInArray(projects, "value", "id");
      editFormData.editClientProjects.value = projects;
      dispatch(
        getSingleAction({
          formName: "editClientModal",
          formData: editFormData,
        })
      );
    });
  };
}

export function getClients() {
  return (dispatch) => {
    return get("/api/clients").then((response) => {
      dispatch(getClientsAction(response.data));
    });
  };
}

export function addClient(data, toggleBlocking) {
  toggleBlocking();
  return (dispatch) => {
    post("/api/clients", data).then(() => {
      dispatch(getClients());
      toggleBlocking();
    });
  };
}

export function editClient(id, data, toggleBlocking) {
  toggleBlocking();
  return (dispatch) => {
    put("/api/clients/" + id, data).then(() => {
      dispatch(getClients());
      toggleBlocking();
    });
  };
}

export function deleteClient(id, toggleBlocking) {
  toggleBlocking();
  return (dispatch) => {
    remove("/api/clients/" + id).then(() => {
      dispatch(getClients());
      toggleBlocking();
    });
  };
}