import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import SelectInput from "../../inputs/select-input/select-input";
import {
  checkErrorsAction,
  deleteInputDataAction,
} from "../../../common/actions/form.actions";
import { validateForm } from "../../../common/validate.function";
import {
  getDefaultValueForMultiValueSelectInput,
  listingFormPropertyId,
  addingParamToObjectsInArray,
} from "../../../common/utility.functions";
import { getProjects } from "../../../common/api/projects.api";

const EDIT_CLIENT_PROJECTS = "editClientProjects";

const mapStateToProps = (state, props) => {
  return {
    formData: state.forms[props.formName],

    editClientProjects:
      state.forms[props.formName] &&
      state.forms[props.formName][EDIT_CLIENT_PROJECTS]
        ? state.forms[props.formName][EDIT_CLIENT_PROJECTS]
        : {},
    projects: state.projects && state.projects.length > 0 ? state.projects : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendErrors: (formName, formData) =>
      dispatch(checkErrorsAction({ formName, formData })),
    deleteInputData: (payload) => dispatch(deleteInputDataAction(payload)),
    getProjects: () => {
      dispatch(getProjects());
    },
  };
};

class EditClientModal extends Component {
  onConfirm = () => {
    const result = validateForm(this.props.formData);
    if (result.isValid) {
      this.props.onConfirm({
        projectIds: listingFormPropertyId(this.props.editClientProjects.value),
      });

      this.onClose();
    } else {
      this.props.sendErrors(this.props.formName, result.formData);
    }
  };

  onClose = () => {
    this.props.deleteInputData({
      formName: this.props.formName,
      inputName: EDIT_CLIENT_PROJECTS,
    });
    this.props.onClose();
  };

  render() {
    var projects = addingParamToObjectsInArray(
      this.props.projects,
      "label",
      "name"
    );
    projects = addingParamToObjectsInArray(projects, "value", "id");
    return (
      <Modal
        show={this.props.showModal}
        onHide={this.onClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="modal-title">Edit a client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectInput
            className="react-select-container"
            labelClassName="modal-input-label"
            options={projects}
            isMulti={true}
            name={EDIT_CLIENT_PROJECTS}
            formName={this.props.formName}
            label={"Projects"}
            validationTypes={["required"]}
            placeholder="Select projects"
            showAllSelected={true}
            defaultValue={getDefaultValueForMultiValueSelectInput(
              this.props.projects,
              this.props.editClientProjects
            )}
            errorClassName="modal-error-select-input"
            errorMessageClassName="modal-error-message"
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-button-container">
            <button
              className="modal-button-confirm modal-button"
              onClick={this.onConfirm}
            >
              CONFIRM
            </button>
            <button
              className="modal-button-cancel modal-button"
              onClick={this.onClose}
            >
              CANCEL
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditClientModal);
