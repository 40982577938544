import React from "react";
import OverviewTable from "../../components/table/overviewTable";

import {
  totalsColumns,
  totalsStyles,
  eventRowStyles,
} from "../../common/consts";

const Totals = () => {
  return (
    <OverviewTable
      columns={totalsColumns}
      customStyles={totalsStyles}
      conditionalRowStyles={eventRowStyles}
      title="Totals"
      headingClass="totals"
      selectColor="#faa870"
      isTotals
    />
  );
};

export default Totals;
