import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createForm } from "../../common/utility.functions";
import { checkErrorsAction } from "../../common/actions/form.actions";
import {
  createModalAction,
  toggleModalAction,
} from "../../common/actions/modal.actions";
import CreateClientModal from "../../components/modals/client-modals/create-client-modal";
import EditClientModal from "../../components/modals/client-modals/edit-client-modal";
import { BasicTable } from "../../components/table/table";
import { adminsStyles } from "../../common/consts";
import {
  getClients,
  getClient,
  editClient,
  deleteClient
} from "../../common/api/clients.api";
import { resendEmailApi } from "../../common/api/utility.api";
import DeleteModal from "../../components/modals/delete-modal"

const FORM_NAME = "clients";
const CREATE_CLIENT_MODAL = "createClientModal";
const EDIT_CLIENT_MODAL = "editClientModal";
const DELETE_CLIENT_MODAL = "deleteClientModal";

const mapStateToProps = (state) => {
  return {
    formData: state.forms[FORM_NAME],
    editFormData: state.forms[EDIT_CLIENT_MODAL],
    showCreateClientModal:
      state.modals && state.modals[CREATE_CLIENT_MODAL]
        ? state.modals[CREATE_CLIENT_MODAL]
        : false,
    showEditClientModal:
      state.modals && state.modals[EDIT_CLIENT_MODAL]
        ? state.modals[EDIT_CLIENT_MODAL]
        : false,
    showDeleteClientModal:
      state.modals && state.modals[DELETE_CLIENT_MODAL]
        ? state.modals[DELETE_CLIENT_MODAL]
        : false,
    tableData: state.clients && state.clients.length > 0 ? state.clients : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createForm: (formData, formName) =>
      createForm(formData, dispatch, formName),
    sendErrors: (formData) =>
      dispatch(checkErrorsAction({ formName: FORM_NAME, formData })),
    createModal: (modalName) => dispatch(createModalAction({ modalName })),
    toggleModal: (modalName) => dispatch(toggleModalAction({ modalName })),
    getClients: () => {
      dispatch(getClients());
    },
    getClient: (id, data) => {
      dispatch(getClient(id, data));
    },
    editClient: (id, data, toggleBlocking) => {
      dispatch(editClient(id, data, toggleBlocking));
    },
    deleteClient: (id, toggleBlocking) => {
      dispatch(deleteClient(id, toggleBlocking));
    },
    resendEmail: (email) => {
      dispatch(resendEmailApi(email));
    },
  };
};

class Clients extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.props.createForm(this.props.formData, FORM_NAME);
    this.props.createForm(this.props.formData, EDIT_CLIENT_MODAL);
    this.props.createModal(CREATE_CLIENT_MODAL);
    this.props.createModal(DELETE_CLIENT_MODAL);
    this.props.getClients();
  }

  columns = [
    {
      name: "Client Name",
      // selector: "name",
      width: "calc (60% - 55px) !important",
      cell: (row) => <div title={row.email}>{row.name}</div>,
    },
    {
      actions: true,
      width: "15% !important",
      cell: (row) => (
        <button
          onClick={() => {
            this.onResend(row);
          }}
          className={"small-button"}
        >
          Resend
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      actions: true,
      width: "10% !important",
      cell: (row) => (
        <button
          onClick={() => {
            this.onEdit(row);
          }}
          className={"small-button"}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      actions: true,
      width: "15% !important",
      cell: (row) => (
        <button
          onClick={() => {
            this.onDelete(row);
          }}
          className={"small-button"}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  openModal = (name) => {
    this.props.toggleModal(name);
  };

  executeEdit = (data) => {
    this.props.editClient(this.state.id, data, this.props.toggleBlockUI);
  };

  executeDelete = () => {
    this.props.deleteClient(this.state.id, this.props.toggleBlockUI);
    this.closeModal(DELETE_CLIENT_MODAL)
  }

  closeModal = (name) => {
    this.props.toggleModal(name);
    this.setState({});
  };

  onEdit = (row) => {
    this.setState({ id: row.id });
    this.openModal(EDIT_CLIENT_MODAL);
    this.props.getClient(row.id, this.props.editFormData);
  };

  onDelete = (row) => {
    this.setState({ id: row.id });
    this.openModal(DELETE_CLIENT_MODAL);
  };

  onResend = (row) => {
    this.props.resendEmail(row.email);
  };

  render() {
    return (
      <div className="full-page-body">
        <div className="body">
          <div className="card-container">
            <div className="headline-container">
              <div className="headline-title">Clients</div>
              <button
                className="small-button"
                onClick={() => {
                  this.openModal(CREATE_CLIENT_MODAL);
                }}
              >
                Add new
              </button>
            </div>
            <div className="table-container">
              <BasicTable
                columns={this.columns}
                data={this.props.tableData}
                customStyles={adminsStyles}
                expandableRows={true}
                expandOnRowClicked={true}
                expandableRowsComponent={true}
                expandableComponent={"clients"}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
              />
            </div>
            <CreateClientModal
              formName={FORM_NAME}
              modalName={CREATE_CLIENT_MODAL}
              showModal={this.props.showCreateClientModal}
              onClose={() => {
                this.closeModal(CREATE_CLIENT_MODAL);
              }}
              blockState={this.props.blockState}
              toggleBlockUI={this.props.toggleBlockUI}
            />
            <EditClientModal
              formName={EDIT_CLIENT_MODAL}
              modalName={EDIT_CLIENT_MODAL}
              showModal={this.props.showEditClientModal}
              onConfirm={this.executeEdit}
              onClose={() => {
                this.closeModal(EDIT_CLIENT_MODAL);
              }}
              blockState={this.props.blockState}
            />
            <DeleteModal
              modalTitle={"Confirm Client removal"}
              modalBody={"If you confirm, this Client will no longer be able to access the aplication, but their data will remain intact."}
              showModal={this.props.showDeleteClientModal}
              onConfirm={this.executeDelete}
              onClose={() => {
                this.closeModal(DELETE_CLIENT_MODAL);
              }}
              blockState={this.props.blockState}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Clients)
);
