import {
  CREATE_FORM,
  CLEAR_FORM_DATA,
  UPDATE_FORM_DATA,
  ADD_INPUTS_TO_FORM,
  ADD_INPUT_DATA,
  UPDATE_INPUT_DATA,
  DELETE_INPUT_DATA,
  CHECK_ERRORS,
  GET_SINGLE,
} from "../actions/form.actions";

const initialState = {};

const formsReducer = (state = initialState, action) => {
  let newState = { ...state };

  switch (action.type) {
    case CREATE_FORM: {
      newState[action.payload.formName] = {};
      return newState;
    }

    case CLEAR_FORM_DATA: {
      let arrayOfInputsInThisForm = Object.keys(action.payload.formData);
      arrayOfInputsInThisForm.forEach((inputName) => {
        action.payload.formData[inputName].value = "";
        action.payload.formData[inputName].errorMessage = null;
      });
      newState[action.payload.formName] = {
        ...action.payload.formData,
      };
      return newState;
    }

    case UPDATE_FORM_DATA: {
      let arrayOfInputsInThisForm = Object.keys(action.payload.formData);
      arrayOfInputsInThisForm.forEach((inputName) => {
        action.payload.formData[inputName].value =
          action.payload.inputsData[inputName];
        action.payload.formData[inputName].errorMessage = null;
      });
      newState[action.payload.formName] = {
        ...action.payload.formData,
      };
      return newState;
    }

    case ADD_INPUTS_TO_FORM: {
      newState[action.payload.formName] = {
        ...newState[action.payload.formName],
        ...action.payload.formData,
      };
      return newState;
    }

    case ADD_INPUT_DATA: {
      newState[action.payload.formName][action.payload.inputName] =
        action.payload.inputData;
      return newState;
    }

    case UPDATE_INPUT_DATA: {
      newState[action.payload.formName][action.payload.inputName] = {
        ...newState[action.payload.formName][action.payload.inputName],
        ...action.payload.inputData,
      };
      return newState;
    }

    case DELETE_INPUT_DATA: {
      delete newState[action.payload.formName][action.payload.inputName];
      return newState;
    }

    case CHECK_ERRORS: {
      newState[action.payload.formName] = action.payload.formData;
      return newState;
    }

    case GET_SINGLE: {
      newState[action.payload.formName] = action.payload.formData;
      return newState;
    }

    default:
      return state;
  }
};

export default formsReducer;
