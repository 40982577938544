import React, { Component } from "react";
import { connect } from "react-redux";
import EmailInput from "../../../components/inputs/email-input/email-input";
import Modal from "react-bootstrap/Modal";
import {
  checkErrorsAction,
  deleteInputDataAction,
} from "../../../common/actions/form.actions";
import { validateInput } from "../../../common/validate.function";
import { forgotPassword } from "../../../common/api/login.api";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";

const mapStateToProps = (state, props) => {
  return {
    formData: state.forms[props.formName],
    forgotPasswordEmail:
      state.forms[props.formName] &&
      state.forms[props.formName]["forgotPasswordEmail"]
        ? state.forms[props.formName]["forgotPasswordEmail"]
        : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendErrors: (formName, formData) =>
      dispatch(checkErrorsAction({ formName, formData })),
    deleteInputData: (payload) => dispatch(deleteInputDataAction(payload)),
    forgotPassword: (data) => dispatch(forgotPassword(data)),
  };
};

class ForgotPasswordModal extends Component {
  onConfirm = () => {
    let errorMessage = validateInput(
      this.props.forgotPasswordEmail.value,
      this.props.forgotPasswordEmail.validationTypes,
      this.props.forgotPasswordEmail.validationParams
    );
    if (!errorMessage) {
      this.props.block();
      this.props
        .forgotPassword(this.props.forgotPasswordEmail.value)
        .then(() => {
          this.props.block();
          this.onClose();
        });
    }
  };

  onClose = () => {
    this.props.deleteInputData({
      formName: "loginForm",
      inputName: "forgotPasswordEmail",
    });
    this.props.onClose();
  };

  render() {
    return (
      <Modal
        show={this.props.showModal}
        onHide={this.onClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <BlockUi
          className={"loader"}
          message={"L O A D I N G"}
          tag="div"
          blocking={this.props.blockState}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Reset password
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-text">
              Please insert your email address and confirm with button.
            </div>
            <EmailInput
              labelClassName="login-input-label"
              className="login-input"
              type="email"
              label="Email Address"
              placeholder="example@email.com"
              name="forgotPasswordEmail"
              validationTypes={["required", "email"]}
              formName={this.props.formName}
              errorClassName="login-error-input"
              errorMessageClassName="login-error-message"
              errorMessageText="Account with this email doesn't exist"
            />
          </Modal.Body>
          <Modal.Footer>
            <button className="login-button" onClick={this.onConfirm}>
              Continue
            </button>
          </Modal.Footer>
        </BlockUi>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordModal);
