import { post, get, put } from "./controller.api";
import { getProjectsAction } from "../../common/actions/project.actions";
import { getSingleAction } from "../../common/actions/form.actions";

export function getProject(id, editFormData) {
  return (dispatch) => {
    return get("/api/projects/" + id).then((response) => {
      editFormData.editProjectName.value = response.data[0].name;
      dispatch(
        getSingleAction({
          formName: "editProjects",
          formData: editFormData,
        })
      );
    });
  };
}

export function getProjects() {
  return (dispatch) => {
    return get("/api/projects/").then((response) => {
      dispatch(getProjectsAction(response.data));
    });
  };
}

export function getProjectsForClient() {
  return (dispatch) => {
    return get("/api/projects/client").then((response) => {
      if (response.data.length === 0) {
        dispatch(getProjects())
      }
      else {
        dispatch(getProjectsAction(response.data));
      }
    });
  };
}

export function addProject(data, toggleBlocking) {
  toggleBlocking();
  return (dispatch) => {
    post("/api/projects", data).then(() => {
      dispatch(getProjects());
      toggleBlocking();
    });
  };
}

export function editProject(data, toggleBlocking) {
  toggleBlocking();
  return (dispatch) => {
    put("/api/projects", data).then(() => {
      dispatch(getProjects());
      toggleBlocking();
    });
  };
}
