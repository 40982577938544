export const CREATE_MODAL = "CREATE_MODAL";
export const TOGGLE_MODAL = "TOGGLE_MODAL";

export const createModalAction = payload => {
   return {
      type: CREATE_MODAL,
      payload
   };
};

export const toggleModalAction = payload => {
   return {
      type: TOGGLE_MODAL,
      payload
   };
};
