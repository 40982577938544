export function validateForm(formData) {
  let isValid = true;
  let arrayOfInputsInThisForm = Object.keys(formData);
  arrayOfInputsInThisForm.forEach((inputName) => {
    if (inputName !== "message") {
      let errorMessage = validateInput(
        formData[inputName].value,
        formData[inputName].validationTypes,
        formData[inputName].validationParams
      );
      if (errorMessage) {
        isValid = false;
        formData[inputName] = { ...formData[inputName], errorMessage };
      }
    }
  });
  return { isValid, formData };
}

export function validateInput(inputValue, validationTypes, validationParams) {
  let errorMessage = null;
  if (!validationTypes[0] || !validationTypes) {
    return errorMessage;
  } else {
    let isError = false;
    validationTypes.forEach((currentType) => {
      if (!isError) {
        errorMessage = checkValueForError(
          currentType,
          inputValue,
          validationParams
        );
        if (errorMessage) {
          isError = true;
        }
      }
    });
    if (errorMessage) return errorMessage;
    else return null;
  }
}

function checkValueForError(validationType, inputValue, validationParams) {
  switch (validationType) {
    case "required":
      if (
        !inputValue ||
        (Array.isArray(inputValue) && inputValue.length === 0)
      ) {
        return "This field is required";
      }
      return false;

    case "email":
      if (inputValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        return false;
      }
      return "Write a valid email address";

    case "mustMatch":
      if (inputValue === validationParams.parameter.value) {
        return false;
      }
      return "Password and Confirm Password must match";

    case "maxDate":
      if (inputValue <= validationParams.maxDate) {
        return false;
      }
      return "Invalid date";

    case "minDate":
      if (inputValue >= validationParams.minDate) {
        return false;
      }
      return "Date too small";

    case "mustBeHigherDate":
      if (inputValue >= validationParams.parameter.value) {
        return false;
      }
      return " ";

    case "mustBeLowerDate":
      if (
        !validationParams.parameter ||
        !validationParams.parameter.value ||
        inputValue <= validationParams.parameter.value
      ) {
        return false;
      }
      return " ";

    case "website":
      if (
        inputValue.match(
          /(http|https):\/\/[a-zA-Z0-9]+((\.[a-z0-9]{2,63})?)\.[a-z]{1}[a-z0-9-]{0,61}[a-z0-9]{1}$/i
        )
      ) {
        return false;
      }
      return "Please enter website of your company in form https://company.com";

    case "number":
      if (inputValue.match(/^[0-9]*$/i)) {
        return false;
      }
      return "Use only numbers";

    case "only-letters-empty-spaces-dots-dashes":
      if (inputValue.match(/^[a-zA-Z-. ]*$/i)) {
        return false;
      }
      return "Use only letters, empty spaces, dots and dashes";

    case "password":
      if (inputValue.match(/\s/)) {
        return "Please don’t use empty spaces";
      } else if (inputValue.length < 8) {
        return "Minimum of 8 characters";
      } else if (
        inputValue.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*d)[a-zA-Zd]{8,}$/)
      )
        return "Use one upper case, one lower case letter and a number";
      return false;

    default:
      return false;
  }
}

export function checkAllForms(forms, formNames) {
  let result = true;
  formNames.forEach((formName) => {
    if (result) {
      if (
        Object.keys(formName).length === 0 ||
        !forms.hasOwnProperty(formName) ||
        !validateForm(forms[formName]).isValid
      ) {
        result = false;
      }
    }
  });
  return result;
}
