import { GET_CLIENTS, GET_CLIENT_BY_ID } from "../actions/client.actions";

const initialState = [];

const clientsReducer = (state = initialState, action) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_CLIENTS: {
      if (action.payload) newState = action.payload;
      return newState;
    }

    case GET_CLIENT_BY_ID: {
      return newState;
    }

    default:
      return state;
  }
};

export default clientsReducer;
