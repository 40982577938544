import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/login/login.page";
import SetPasswordPage from "./pages/set-password/set-password.page";
import ClientProjects from "./pages/client-projects/client-projects.page";
import Events from "./pages/client-events/client-events.page";
import Projects from "./pages/admin-projects/admin-projects.page";
import Clients from "./pages/admin-clients/admin-clients.page";
import Admins from "./pages/admins/admins.page";
import Overview from "./pages/admin-overview/admin-overview.page";
import DirectBeneficiaries from "./pages/direct-beneficiaries/direct-beneficiaries";
import CapacityDevelopment from "./pages/capacity-development/capacity-development";
import Reach from "./pages/reach/reach";
import OnlineReach from "./pages/online-reach/online-reach";
import Totals from "./pages/totals/totals";
import error from "./pages/error-page/error.page";
import { Rerouter } from "./common/rerouter";
import "react-block-ui/style.css";
import { connect } from "react-redux";
import { toggleBlockUIAction } from "./common/actions/blockUI.actions";
import credentials from "./pages/error-page/credentials.page";
import notFound from "./pages/error-page/not-found.page";

const mapStateToProps = (state) => {
  return {
    blockUI: state.blockUI,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleBlockUI: () => {
      dispatch(toggleBlockUIAction());
    },
  };
};

class App extends Component {
  constructor(props) {
    super(props);
    this.toggleBlocking = this.toggleBlocking.bind(this);
  }

  toggleBlocking = () => {
    this.props.toggleBlockUI();
  };

  render() {
    return (
      <Router>
        <div className="App">
          <ToastContainer
            enableMultiContainer
            containerId={"Error"}
            position={toast.POSITION.TOP_RIGHT}
          />
          <ToastContainer
            enableMultiContainer
            containerId={"Success"}
            position={toast.POSITION.TOP_RIGHT}
          />
          <ToastContainer
            enableMultiContainer
            containerId={"Warning"}
            position={toast.POSITION.TOP_LEFT}
            autoClose={10000}
          />
          <Switch>
            <Route path="/expired" component={credentials} />
            <Route path="/error" component={error} />
            <Route path="/setpassword" component={SetPasswordPage} />
            <Route exact path="/" component={Login} />
            <Rerouter exact path="/clientprojects" component={ClientProjects} />
            <Rerouter
              exact
              path="/events"
              component={Events}
              blockState={this.props.blockUI}
              toggleBlockUI={this.toggleBlocking}
            />
            <Rerouter
              exact
              path="/admins"
              component={Admins}
              sideNav={true}
              blockState={this.props.blockUI}
              toggleBlockUI={this.toggleBlocking}
            />
            <Rerouter
              exact
              path="/clients"
              component={Clients}
              sideNav={true}
              blockState={this.props.blockUI}
              toggleBlockUI={this.toggleBlocking}
            />
            <Rerouter
              exact
              path="/allprojects"
              component={Projects}
              sideNav={true}
              blockState={this.props.blockUI}
              toggleBlockUI={this.toggleBlocking}
            />
            <Rerouter
              exact
              path="/adminevents"
              component={Events}
              sideNav={true}
              blockState={this.props.blockUI}
              toggleBlockUI={this.toggleBlocking}
            />
            <Rerouter
              exact
              path="/overview"
              component={Overview}
              sideNav={true}
            />
            <Rerouter
              exact
              path="/directbeneficiaries"
              component={DirectBeneficiaries}
              sideNav={true}
            />
            <Rerouter
              exact
              path="/capacitydevelopment"
              component={CapacityDevelopment}
              sideNav={true}
            />
            <Rerouter exact path="/reach" component={Reach} sideNav={true} />
            <Rerouter
              exact
              path="/onlinereach"
              component={OnlineReach}
              sideNav={true}
            />
            <Rerouter exact path="/totals" component={Totals} sideNav={true} />
            <Route component={notFound} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);