import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Input from "../../inputs/input/input";
import {
  checkErrorsAction,
  deleteInputDataAction,
} from "../../../common/actions/form.actions";
import { validateForm } from "../../../common/validate.function";
import FieldInput from "../../inputs/field-input/field-input";

const mapStateToProps = (state, props) => {
  return {
    formData: state.forms[props.formName],
    eventType:
      state.forms[props.formName] &&
      state.forms[props.formName].editEventOnlineTotal &&
      state.forms[props.formName].editEventOnlineTotal.value
        ? state.forms[props.formName].editEventOnlineTotal.value
        : null,
    editEventComment:
      state.forms[props.formName] &&
      state.forms[props.formName].editEventComment &&
      state.forms[props.formName].editEventComment.value
        ? state.forms[props.formName].editEventComment.value
        : null,
    editEventOnlineTotal:
      state.forms[props.formName] &&
      state.forms[props.formName].editEventOnlineTotal &&
      state.forms[props.formName].editEventOnlineTotal.value
        ? state.forms[props.formName].editEventOnlineTotal.value
        : null,
    editEventMen:
      state.forms[props.formName] &&
      state.forms[props.formName].editEventMen &&
      state.forms[props.formName].editEventMen.value
        ? state.forms[props.formName].editEventMen.value
        : null,
    editEventWomen:
      state.forms[props.formName] &&
      state.forms[props.formName].editEventWomen &&
      state.forms[props.formName].editEventWomen.value
        ? state.forms[props.formName].editEventWomen.value
        : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendErrors: (formName, formData) =>
      dispatch(checkErrorsAction({ formName, formData })),
    deleteInputData: (payload) => dispatch(deleteInputDataAction(payload)),
  };
};

class EditEventModal extends Component {
  onConfirm = () => {
    const result = validateForm(this.props.formData);
    if (result.isValid) {
      let client =
        this.props.eventTypeId === 4
          ? {
              totalAttendants: this.props.editEventOnlineTotal,
            }
          : {
              femaleAttendants: this.props.editEventWomen,
              maleAttendants: this.props.editEventMen,
            };
      client.id = this.props.eventId;
      client.comment = this.props.editEventComment;
      this.props.onConfirm(client);
      this.onClose();
    }
  };

  onClose = () => {
    this.props.deleteInputData({
      formName: this.props.formName,
      inputName: "editEventWomen",
    });
    this.props.deleteInputData({
      formName: this.props.formName,
      inputName: "editEventMen",
    });
    this.props.deleteInputData({
      formName: this.props.formName,
      inputName: "editEventComment",
    });
    this.props.deleteInputData({
      formName: this.props.formName,
      inputName: "editEventOnlineTotal",
    });

    this.props.onClose();
  };

  render() {
    return (
      <Modal
        show={this.props.showModal}
        onHide={this.onClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="modal-title">Edit action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.eventTypeId !== 4 ? (
            <React.Fragment>
              <Input
                labelClassName="modal-input-label"
                className="modal-input"
                type="text"
                label="Women"
                placeholder="Number of attending women"
                name={"editEventWomen"}
                validationTypes={["required"]}
                formName={this.props.formName}
                errorClassName="modal-error-input"
                errorMessageClassName="modal-error-message"
                defaultValue={this.props.editEventWomen}
              />
              <Input
                labelClassName="modal-input-label"
                className="modal-input"
                type="text"
                label="Men"
                placeholder="Number of attending men"
                name={"editEventMen"}
                validationTypes={["required"]}
                formName={this.props.formName}
                errorClassName="modal-error-input"
                errorMessageClassName="modal-error-message"
                defaultValue={this.props.editEventMen}
              />
            </React.Fragment>
          ) : (
            <Input
              labelClassName="modal-input-label"
              className="modal-input"
              type="text"
              label="Total reach"
              placeholder="Total number of people reached"
              name={"editEventOnlineTotal"}
              validationTypes={["required"]}
              formName={this.props.formName}
              errorClassName="modal-error-input"
              errorMessageClassName="modal-error-message"
              defaultValue={this.props.editEventOnlineTotal}
            />
          )}
          <FieldInput
            labelClassName="modal-input-label"
            className="modal-input"
            type="text"
            label="Comment"
            name={"editEventComment"}
            validationTypes={[]}
            formName={this.props.formName}
            errorClassName="modal-error-input"
            errorMessageClassName="modal-error-message"
            defaultValue={this.props.editEventComment}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-button-container">
            <button
              className="modal-button-confirm modal-button"
              onClick={this.onConfirm}
            >
              CONFIRM
            </button>
            <button
              className="modal-button-cancel modal-button"
              onClick={this.onClose}
            >
              CANCEL
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditEventModal);
