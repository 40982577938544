import { post, put, remove, get } from "./controller.api";
import {
  getEventsAction,
  getEventsForAdminAction,
  getTotalEventsForAdminAction,
} from "../../common/actions/event.actions";
import { getSingleAction } from "../../common/actions/form.actions";

export function getEvents(url) {
  return (dispatch) => {
    return get("/api/events" + url).then((response) => {
      dispatch(getEventsAction(response.data.data));
    });
  };
}

export function getEvent(id, editFormData) {
  return (dispatch) => {
    return get("/api/events/?id=" + id).then((response) => {
      if (response.data.data[0].eventTypeId === 4) {
        editFormData.editEventComment.value = response.data.data[0].comment;
        editFormData.editEventOnlineTotal.value =
          response.data.data[0].totalAttendants;
      } else {
        editFormData.editEventComment.value = response.data.data[0].comment;
        editFormData.editEventMen.value = response.data.data[0].maleAttendants;
        editFormData.editEventWomen.value =
          response.data.data[0].femaleAttendants;
      }
      dispatch(
        getSingleAction({
          formName: "editEventModal",
          formData: editFormData,
        })
      );
    });
  };
}

export function addEvent(data, url, toggleBlocking) {
  toggleBlocking();
  return (dispatch) => {
    post("/api/events", data).then(() => {
      dispatch(getEvents(url));
      toggleBlocking();
    });
  };
}

export function editEvent(data, url, toggleBlocking) {
  toggleBlocking();
  return (dispatch) => {
    put("/api/events", data).then(() => {
      dispatch(getEvents(url));
      toggleBlocking();
    });
  };
}

export function deleteEvent(id, url, toggleBlocking) {
  toggleBlocking();
  return (dispatch) => {
    remove("/api/events/" + id).then(() => {
      dispatch(getEvents(url));
      toggleBlocking();
    });
  };
}

export function getEventsTotal(url) {
  return (dispatch) => {
    get("/api/Admins/view/event/" + url).then((res) => {
      dispatch(getEventsForAdminAction(res.data));
    });
  };
}

export function getTotals(url) {
  return (dispatch) => {
    get("/api/Admins/view/total" + url).then((res) => {
      dispatch(getTotalEventsForAdminAction(res.data));
    });
  };
}
