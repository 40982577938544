import React from "react";
import OverviewTable from "../../components/table/overviewTable";

import {
  onlineReachColumns,
  eventStyles,
  eventRowStyles,
} from "../../common/consts";

const OnlineReach = () => {
  return (
    <OverviewTable
      type={4}
      columns={onlineReachColumns}
      customStyles={eventStyles}
      conditionalRowStyles={eventRowStyles}
      title="Social media reach"
      headingClass="totals"
      selectColor="#faa870"
    />
  );
};

export default OnlineReach;
