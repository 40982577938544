import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import { BasicTable } from "../../components/table/table";
import { getProjects } from "../../common/api/projects.api";
import { getEventsTotal, getTotals } from "../../common/api/events.api";
import { getYears } from "../../common/api/admins.api";
import {
  mapProjectsToSelectOptions,
  mapYearsToSelectOptions,
} from "../../common/utility.functions";
import { get } from "../../common/api/controller.api";

const mapStateToProps = (state) => {
  console.log(state);
  return {
    projects: state.projects ? state.projects : [],
    events: state.events ? state.events : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEvents: (url) => {
      dispatch(getEventsTotal(url));
    },
    getProjects: () => {
      dispatch(getProjects());
    },
    getTotalEvents: (url) => {
      dispatch(getTotals(url));
    },
    getYears: () => {
      dispatch(getYears());
    },
  };
};

class OverviewTable extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = {
      years: [],
      selectedYear: "",
      selectedProject: { value: "", label: "" },
    };
  }

  selectStyles = {
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      color: "white",
      background: this.props.selectColor || "#faa870",
    }),
    container: (base) => ({
      ...base,
      minWidth: 130,
    }),
    control: (base) => ({
      ...base,
      minWidth: 130,
      cursor: "pointer",
      background: this.props.selectColor || "#faa870",
    }),
    valueContainer: (base) => ({
      ...base,
      background: this.props.selectColor || "#faa870",
    }),
    placeholder: (base) => ({
      ...base,
      color: "white",
    }),
    menu: (base) => ({
      ...base,
      background: this.props.selectColor || "#faa870",
    }),
    singleValue: (base) => ({
      ...base,
      color: "white",
    }),
  };

  urlBuilder() {
    const url = `?year=${this.state.selectedYear}${
      Boolean(this.state.selectedProject.value.length !== "") &&
      `&projectId=${this.state.selectedProject.value}`
    }`;
    return url;
  }

  componentDidMount() {
    this.props.getProjects();
    get("/api/Admins/available-years").then((res) => {
      this.setState({ years: res.data });
    });

    if (this.props.isTotals) {
      this.props.getTotalEvents(`${this.urlBuilder()}`);
    } else {
      this.props.getEvents(`${this.props.type}${this.urlBuilder()}`);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.selectedYear.toString() !==
        prevState.selectedYear.toString() ||
      this.state.selectedProject.value.toString() !==
        prevState.selectedProject.value.toString()
    ) {
      if (this.props.isTotals) {
        this.props.getTotalEvents(`${this.urlBuilder()}`);
      } else {
        this.props.getEvents(`${this.props.type}${this.urlBuilder()}`);
      }
    }
  }

  handleYearChange = (year) => {
    this.setState({ selectedYear: year.label });
  };

  handleProjectChange = (id) => {
    this.setState({ selectedProject: id });
  };

  render() {
    const columns = this.props.isTotals
      ? this.props.columns(this.state.selectedYear || this.state.years[0])
      : this.props.columns;
    const data = this.props.isTotals
      ? this.props.events[0] && this.props.events[0].events
        ? this.props.events
        : []
      : this.props.events;
    return (
      <div className="login-page-body">
        <div className="body">
          <div className="card-container">
            <div className="title-container">
              <div className={`title-title ${this.props.headingClass}`}>
                {this.props.title}
              </div>
              <div className="line-wrapper">
                <Select
                  defaultValue={{ label: "All projects", value: "" }}
                  placeholder="Projects"
                  options={mapProjectsToSelectOptions([
                    { id: "", name: "All projects" },
                    ...this.props.projects,
                  ])}
                  onChange={(value) => this.handleProjectChange(value)}
                  styles={this.selectStyles}
                />
                {Boolean(this.state.years.length) && (
                  <Select
                    defaultValue={{
                      label: this.state.years[0],
                      value: this.state.years[0],
                    }}
                    placeholder="Years"
                    options={mapYearsToSelectOptions(this.state.years)}
                    onChange={(value) => this.handleYearChange(value)}
                    styles={this.selectStyles}
                  />
                )}
              </div>
            </div>
            <div className="table-container">
              <BasicTable
                columns={columns}
                data={data}
                customStyles={this.props.customStyles}
                conditionalRowStyles={this.props.eventRowStyles}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OverviewTable)
);
