import Select from "react-select";
import "./select-input.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addInputDataAction,
  updateInputDataAction,
} from "../../../common/actions/form.actions";
import { validateInput } from "../../../common/validate.function";
import { COLOR_STYLES } from "./select-input-consts";

const mapStateToProps = (state, props) => {
  return {
    input:
      state.forms[props.formName] && state.forms[props.formName][props.name]
        ? state.forms[props.formName][props.name]
        : {},
    selectedOptions:
      state.forms[props.formName] && state.forms[props.formName][props.name]
        ? state.forms[props.formName][props.name].value
        : props.defaultValue,
    secondFormInput:
      props.secondForm &&
      state.forms[props.secondForm] &&
      state.forms[props.secondForm][props.name] &&
      state.forms[props.secondForm][props.name].value
        ? state.forms[props.secondForm][props.name].value
        : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addInputDataToStore: (payload) => dispatch(addInputDataAction(payload)),
    updateInputDataInStore: (payload) =>
      dispatch(updateInputDataAction(payload)),
  };
};

class SelectInput extends Component {
  state = { maxReached: false };
  selectedOptions = this.props.selectedOptions.value;

  UNSAFE_componentWillMount() {
    if (!this.props.input.value) {
      this.props.addInputDataToStore({
        formName: this.props.formName,
        inputName: this.props.name,
        inputData: {
          value: this.props.defaultValue,
          validationTypes: this.props.validationTypes,
          errorMessage: null,
        },
      });
      if (this.props.secondForm && !this.props.secondFormInput) {
        this.props.addInputDataToStore({
          formName: this.props.secondForm,
          inputName: this.props.name,
          inputData: { value: 0 },
        });
      }
    }
  }

  componentDidUpdate() {
    if (
      !this.props.showAllSelected &&
      document.getElementsByName(this.props.name)[0]
    ) {
      let divCollection = document.getElementsByName(this.props.name)[0]
        .parentElement.children[1].children[0].children;

      let divCollectionReload = document.getElementsByName(this.props.name)[0]
        .parentElement.children[0].children[0]
        ? document.getElementsByName(this.props.name)[0].parentElement
            .children[0].children[0].children
        : "null";

      divCollection =
        divCollection.length > 0 ? divCollection : divCollectionReload;
      if (this.props.selectedOptions && this.props.selectedOptions.length > 0) {
        for (let i = 0; i < divCollection.length - 1 && i < 3; i++) {
          divCollection[i].children[0].innerHTML =
            this.props.selectedOptions[i].label;
          divCollection[i].children[1].style.display = "flex";
          divCollection[i].style.border = "1px solid #27a9e1";
        }
        if (divCollection && divCollection.length > 4) {
          divCollection[3].style.border = "none";
          divCollection[3].children[0].innerHTML = "...";
          divCollection[3].children[1].style.display = "none";
          divCollection[3].style.display = "flex";
          divCollection[3].style.minWidth = "20px";
        }
        for (let i = 4; i < divCollection.length - 1; i++) {
          divCollection[i].style.display = "none";
        }
      }
    }
  }

  handleBlur = () => {
    let errorMessage = validateInput(
      this.selectedOptions,
      this.props.input.validationTypes,
      this.props.input.validationParams
    );
    if (errorMessage) {
      this.props.updateInputDataInStore({
        formName: this.props.formName,
        inputName: this.props.name,
        inputData: { errorMessage },
      });
    }
  };

  handleChange = (currentlySelectedOptions, event) => {
    this.selectedOptions = { ...currentlySelectedOptions };
    let isChange = false;
    let maxReached = false;
    if (this.props.isMulti) {
      isChange = Array.isArray(currentlySelectedOptions);
      if (isChange) {
        maxReached =
          event.action === "select-option" &&
          currentlySelectedOptions.length > this.props.maxSelect;
        this.setState({ maxReached });
      }
    } else {
      isChange =
        currentlySelectedOptions.value !==
        (this.props.selectedOptions[0] && this.props.selectedOptions[0].value)
          ? true
          : false;
    }
    let selectedOptions = null;
    if (isChange && !maxReached) {
      selectedOptions = currentlySelectedOptions;
    } else {
      selectedOptions = this.props.selectedOptions;
    }
    let errorMessage = validateInput(
      selectedOptions,
      this.props.validationTypes
    );
    this.props.updateInputDataInStore({
      formName: this.props.formName,
      inputName: this.props.name,
      inputData: {
        value: selectedOptions,
        validationTypes: this.props.validationTypes,
        errorMessage: errorMessage,
      },
    });

    if (this.props.onChange) {
      this.props.updateInputDataInStore({
        formName: this.props.secondForm,
        inputName: this.props.name,
        inputData: { value: selectedOptions.value },
      });

      this.props.onChange(
        selectedOptions.value === 0 ? null : selectedOptions.value
      );
    }
  };

  noOptionsMessage = () => {
    return this.props.noOptionsMessage;
  };

  render() {
    var isMulti = this.props.isMulti;
    const { maxReached } = this.state;
    return (
      <React.Fragment>
        {this.props.label && (
          <label
            className={this.props.labelClassName || "standard-input-label"}
          >
            {this.props.label}
          </label>
        )}
        <Select
          className={
            this.props.input.errorMessage
              ? this.props.errorClassName || ""
              : this.props.className || ""
          }
          closeMenuOnSelect={isMulti ? false : true}
          defaultValue={this.props.input.value || this.props.defaultValue}
          value={this.props.selectedOptions}
          isMulti={isMulti ? true : false}
          options={maxReached ? this.props.selectedOptions : this.props.options}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          noOptionsMessage={
            isMulti ? this.noOptionsMessageMulti : this.noOptionsMessage
          }
          placeholder={
            this.props.placeholder ? this.props.placeholder : "Select..."
          }
          name={this.props.name}
          styles={this.props.coloredDot ? COLOR_STYLES : false}
        />
        {this.props.input.errorMessage && (
          <p className={this.props.errorMessageClassName || "error-message"}>
            {this.props.input.errorMessage}
          </p>
        )}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectInput);
