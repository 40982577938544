import { combineReducers } from "redux";
import forms from "./form.reducer";
import modals from "./modal.reducer";
import projects from "./projects.reducer";
import admins from "./admins.reducer";
import clients from "./clients.reducer";
import events from "./events.reducer";
import blockUI from "./blockUI.reducer";

export default combineReducers({
  admins,
  blockUI,
  clients,
  events,
  forms,
  modals,
  projects,
});
