import { TOGGLE_BLOCK } from "../actions/blockUI.actions";

const initialState = false;

const blockUIReducer = (state = initialState, action) => {
  let newState = state;

  switch (action.type) {
    case TOGGLE_BLOCK: {
      newState = !newState;
      return newState;
    }

    default:
      return state;
  }
};

export default blockUIReducer;
