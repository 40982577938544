import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addInputDataAction,
  updateInputDataAction,
} from "../../../common/actions/form.actions";
import { validateInput } from "../../../common/validate.function";

const mapStateToProps = (state, props) => {
  return {
    input:
      state.forms[props.formName] && state.forms[props.formName][props.name]
        ? state.forms[props.formName][props.name]
        : {},
    errorMessage:
      state.forms[props.formName] &&
      state.forms[props.formName][props.name] &&
      state.forms[props.formName][props.name].errorMessage
        ? state.forms[props.formName][props.name].errorMessage
        : null,
    parameter:
      state.forms[props.formName] &&
      props.validationParams &&
      state.forms[props.formName][props.validationParams.paramName]
        ? state.forms[props.formName][props.validationParams.paramName]
        : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addInputDataToStore: (payload) => dispatch(addInputDataAction(payload)),
    updateInputDataInStore: (payload) =>
      dispatch(updateInputDataAction(payload)),
  };
};

class Input extends Component {
  UNSAFE_componentWillMount() {
    if (!this.props.input.value) {
      this.props.addInputDataToStore({
        formName: this.props.formName,
        inputName: this.props.name,
        inputData: {
          value: this.props.defaultValue,
          validationTypes: this.props.validationTypes,
          errorMessage: null,
          validationParams: {
            ...this.props.validationParams,
            parameter: this.props.parameter,
          },
        },
      });
    }
  }

  handleChange = (event) => {
    let errorMessage = validateInput(
      event.target.value,
      this.props.validationTypes,
      { ...this.props.validationParams, parameter: this.props.parameter }
    );
    this.props.updateInputDataInStore({
      formName: this.props.formName,
      inputName: this.props.name,
      inputData: {
        value: event.target.value,
        validationTypes: this.props.validationTypes,
        errorMessage: errorMessage ? errorMessage : null,
        validationParams: {
          ...this.props.validationParams,
          parameter: this.props.parameter,
        },
      },
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.props.label && (
          <label
            className={this.props.labelClassName || "standard-input-label"}
            htmlFor={this.props.type}
          >
            {this.props.label}
          </label>
        )}
        <input
          type={this.props.type}
          className={
            this.props.errorMessage
              ? this.props.errorClassName || "error-input"
              : this.props.className || "standard-input"
          }
          placeholder={this.props.placeholder}
          name={this.props.name}
          onChange={this.handleChange}
          onBlur={this.handleChange}
          defaultValue={this.props.input.value || this.props.defaultValue}
        />
        {this.props.errorMessage && (
          <p className={this.props.errorMessageClassName || "error-message"}>
            {this.props.errorMessage}
          </p>
        )}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Input);
