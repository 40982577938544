import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Input from "../../components/inputs/input/input";
import EmailInput from "../../components/inputs/email-input/email-input";
import { createForm } from "../../common/utility.functions";
import { checkErrorsAction } from "../../common/actions/form.actions";
import { validateForm } from "../../common/validate.function";
import logo from "../../images/logo.jpg";
import {
  createModalAction,
  toggleModalAction,
} from "../../common/actions/modal.actions";
import ForgotPasswordModal from "./forgotten-password-modal/forgotten-password-modal";
import { loginApiCall } from "../../common/api/login.api";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";

const FORM_NAME = "loginForm";
const FORGOT_PASSWORD_MODAL = "forgotPasswordModal";

const mapStateToProps = (state) => {
  return {
    formData: state.forms[FORM_NAME],
    showForgotPasswordModal:
      state.modals && state.modals[FORGOT_PASSWORD_MODAL]
        ? state.modals[FORGOT_PASSWORD_MODAL]
        : false,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createForm: (formData, formName = FORM_NAME) =>
      createForm(formData, dispatch, formName),
    sendErrors: (formData) =>
      dispatch(checkErrorsAction({ formName: FORM_NAME, formData })),
    createModal: (modalName) => dispatch(createModalAction({ modalName })),
    toggleModal: (modalName) => dispatch(toggleModalAction({ modalName })),
  };
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.props.createForm(this.props.formData);
    this.props.createModal(FORGOT_PASSWORD_MODAL);
    this.toggleBlocking = this.toggleBlocking.bind(this);
    this.state = {
      blocking: false,
    };
  }

  toggleBlocking = () => {
    this.setState({ blocking: !this.state.blocking });
  };

  openForgotPasswordModal = () => {
    this.props.toggleModal(FORGOT_PASSWORD_MODAL);
  };

  closeForgotPasswordModal = () => {
    this.props.toggleModal(FORGOT_PASSWORD_MODAL);
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      this.finishLogin();
    }
  };

  finishLogin = () => {
    const result = validateForm(this.props.formData);
    if (result.isValid) {
      this.toggleBlocking();
      loginApiCall({
        email: this.props.formData.email.value,
        password: this.props.formData.password.value,
      }).then((response) => {
        this.toggleBlocking();
        if (response.data && response.data.data) {
          let accessToken = response.data.data.jwToken;
          localStorage.setItem("accessToken", accessToken);
          if (response.data.data.roles[0] === "Client") {
            if (response.data.data.clientHasMultipleProjects)
              this.props.history.push("/clientprojects");
            else this.props.history.push("/events");
          } else if (response.data.data.roles[0] === "Admin") {
            this.props.history.push("/overview");
          }
        }
      });
      this.toggleBlocking();
    } else {
      this.props.sendErrors(result.formData);
    }
  };

  render() {
    return (
      <div className="full-page-body" onKeyDown={this.onKeyDown}>
        <BlockUi
          className={"loader"}
          message={"L O A D I N G"}
          tag="div"
          blocking={this.state.blocking}
        >
          <div className="logo-container">
            <img className="logo-picture" src={logo} alt="Logo" height="50" />
          </div>
          <div className="body">
            <div className="card-container login-card">
              <div className="login-headline">Sign In</div>
              <EmailInput
                labelClassName="login-input-label"
                className="login-input"
                type="email"
                label="Email Address"
                placeholder="example@email.com"
                name="email"
                validationTypes={["required", "email"]}
                formName={FORM_NAME}
                errorClassName="login-error-input"
                errorMessageClassName="login-error-message"
                errorMessageText="Account with this email doesn't exist"
              />
              <Input
                labelClassName="login-input-label"
                className="login-input"
                type="password"
                label="Password"
                placeholder="Enter your password"
                name="password"
                validationTypes={["required", "password"]}
                formName={FORM_NAME}
                errorClassName="login-error-input"
                errorMessageClassName="login-error-message"
              />
              <button className="login-button" onClick={this.finishLogin}>
                Sign In
              </button>
              <div
                className="set-password"
                onClick={this.openForgotPasswordModal}
              >
                Forgot password?
              </div>
            </div>
          </div>
          <ForgotPasswordModal
            modalName={FORGOT_PASSWORD_MODAL}
            formName={FORM_NAME}
            showModal={this.props.showForgotPasswordModal}
            onClose={this.closeForgotPasswordModal}
            block={this.toggleBlocking}
            blockState={this.state.blocking}
          />
        </BlockUi>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
