export const GET_PROJECTS = "GET_PROJECTS";
export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT";

export const getProjectsAction = (payload) => {
  return {
    type: GET_PROJECTS,
    payload,
  };
};

export const addNewProjectAction = (payload) => {
  return {
    type: ADD_NEW_PROJECT,
    payload,
  };
};
