import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Input from "../../components/inputs/input/input";
import { createForm } from "../../common/utility.functions";
import { addInputDataAction } from "../../common/actions/form.actions";
import { checkErrorsAction } from "../../common/actions/form.actions";
import { validateForm } from "../../common/validate.function";
import { confirmEmail, setPassword } from "../../common/api/login.api";
import logo from "../../images/logo.jpg";
import { toast } from "react-toastify";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";

const FORM_NAME = "setPasswordForm";

const mapStateToProps = (state) => {
  return {
    formData: state.forms[FORM_NAME],
    message:
      state.forms[FORM_NAME] && state.forms[FORM_NAME].message
        ? state.forms[FORM_NAME].message
        : null,
    newPassword:
      state.forms[FORM_NAME] &&
      state.forms[FORM_NAME]["newPassword"] &&
      state.forms[FORM_NAME]["newPassword"].value
        ? state.forms[FORM_NAME]["newPassword"].value
        : null,
    confirmPassword:
      state.forms[FORM_NAME] &&
      state.forms[FORM_NAME]["confirmPassword"] &&
      state.forms[FORM_NAME]["confirmPassword"].value
        ? state.forms[FORM_NAME]["confirmPassword"].value
        : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createForm: (formData) => createForm(formData, dispatch, FORM_NAME),
    addInputDataToStore: (payload) => dispatch(addInputDataAction(payload)),
    sendErrors: (formData) =>
      dispatch(checkErrorsAction({ formName: FORM_NAME, formData })),
    confirmEmail: (data) => dispatch(confirmEmail(data)),
    setPassword: (data) => dispatch(setPassword(data)),
  };
};

class SetPasswordPage extends Component {
  state = { email: null, token: null };
  constructor(props) {
    super(props);
    this.props.createForm();
    this.props.addInputDataToStore({
      formName: FORM_NAME,
      inputName: "message",
      inputData: "Verifying, please wait.",
    });
    this.toggleBlocking = this.toggleBlocking.bind(this);
    this.state = {
      blocking: false,
    };
  }

  toggleBlocking = () => {
    this.setState({ blocking: !this.state.blocking });
  };

  componentDidMount() {
    let params = this.getQueryParams();
    if (params.userId) {
      this.props.confirmEmail(params).then((response) => {
        if (response.data.succeeded === true) {
          // toast.success("Email Confimed!", {
          //   containerId: "Success",
          // });
          this.props.addInputDataToStore({
            formName: FORM_NAME,
            inputName: "message",
            inputData: null,
          });
          this.setState(response.data.data);
        } else {
          // toast.error("Something went wrong! " + response.data.message, {
          //   containerId: "Error",
          // });
          this.props.addInputDataToStore({
            formName: FORM_NAME,
            inputName: "message",
            inputData: " ERROR PAGE ",
          });
        }
      });
    } else {
      setTimeout(
        function () {
          this.props.addInputDataToStore({
            formName: FORM_NAME,
            inputName: "message",
            inputData: null,
          });
        }.bind(this),
        2000
      );
    }
  }

  getQueryParams() {
    let qs = window.location.search;
    qs = qs.split("+").join(" ");

    var params = {},
      tokens,
      re = /[?&]?([^=]+)=([^&]*)/g;

    while ((tokens = re.exec(qs))) {
      params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }
    return params;
  }

  goToLogin = () => {
    window.location.href = "https://commstracker.rs";
  };

  submitNewPassword = () => {
    const result = validateForm(this.props.formData);
    if (result.isValid) {
      let data = {
        email: this.state.email,
        token: this.state.token,
        password: this.props.newPassword,
        confirmPassword: this.props.confirmPassword,
      };
      this.toggleBlocking();
      this.props.setPassword(data).then((response) => {
        if (response.data.succeeded === true) {
          console.log(response);
          toast.success("New password set!", {
            containerId: "Success",
          });
          setTimeout(
            function () {
              this.goToLogin();
            }.bind(this),
            2000
          );
        } else {
          toast.error("Something went wrong! " + response.data.message, {
            containerId: "Error",
          });
        }
        this.toggleBlocking();
      });
    } else {
      this.props.sendErrors(result.formData);
    }
  };

  render() {
    return (
      <BlockUi
        className={"loader"}
        message={"L O A D I N G"}
        tag="div"
        blocking={this.state.blocking}
      >
        <div className="full-page-body">
          <div className="logo-container">
            <img className="logo-picture" src={logo} alt="Logo" height="50" />
          </div>
          <div className="body">
            <div className="card-container login-card">
              {this.props.message ? (
                <div className="login-headline">{this.props.message}</div>
              ) : (
                <div>
                  <div className="login-headline">Set your password</div>
                  <Input
                    labelClassName="login-input-label"
                    className="login-input"
                    type="password"
                    label="New password"
                    placeholder="Enter your new password"
                    name="newPassword"
                    validationTypes={["required", "password"]}
                    formName={FORM_NAME}
                    errorClassName="login-error-input"
                    errorMessageClassName="login-error-message"
                  />
                  <Input
                    labelClassName="login-input-label"
                    className="login-input"
                    type="password"
                    label="Confirm password"
                    placeholder="Re-enter your new password"
                    name="confirmPassword"
                    validationTypes={["required", "mustMatch"]}
                    validationParams={{
                      paramName: "newPassword",
                    }}
                    formName={FORM_NAME}
                    errorClassName="login-error-input"
                    errorMessageClassName="login-error-message"
                  />
                  <button
                    className="login-button"
                    onClick={this.submitNewPassword}
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </BlockUi>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SetPasswordPage)
);
