export const GET_ADMINS = "GET_ADMINS";
export const GET_ADMIN_BY_ID = "GET_ADMIN_BY_ID";
export const GET_AVALIBLE_YEARS = "GET_AVALIBLE_YEARS";

export const getAdminsAction = (payload) => {
  return {
    type: GET_ADMINS,
    payload,
  };
};

export const getAdminByIdAction = (payload) => {
  return {
    type: GET_ADMIN_BY_ID,
    payload,
  };
};

export const getAvalibleYears = (payload) => {
  return {
    type: GET_AVALIBLE_YEARS,
    payload,
  };
};
