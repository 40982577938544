import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Input from "../../inputs/input/input";
import SelectInput from "../../inputs/select-input/select-input";
import DatePickerInput from "../../inputs/date-picker-input/date-picker-input";
import {
  checkErrorsAction,
  deleteInputDataAction,
} from "../../../common/actions/form.actions";
import { validateForm } from "../../../common/validate.function";
import { EVENT_OPTIONS_COLORED } from "../../inputs/select-input/select-input-consts";
import FieldInput from "../../inputs/field-input/field-input";
import { addingParamToObjectsInArray } from "../../../common/utility.functions";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";

const CREATE_EVENT_PROJECT = "createEventProject";
const CREATE_EVENT_TYPE = "createEventType";

const mapStateToProps = (state, props) => {
  return {
    formData: state.forms[props.formName],
    selectedType:
      state.forms[props.formName] &&
      state.forms[props.formName][CREATE_EVENT_TYPE]
        ? state.forms[props.formName][CREATE_EVENT_TYPE]
        : { value: 0, label: "All" },
    projects: state.projects ? state.projects : [],
    newEvent:
      state.forms[props.formName] &&
      state.forms[props.formName][CREATE_EVENT_TYPE]
        ? state.forms[props.formName][CREATE_EVENT_TYPE]
        : {},
    currentClientProject:
      state.forms[props.formName] &&
      state.forms[props.formName][CREATE_EVENT_TYPE]
        ? state.forms[props.formName][CREATE_EVENT_TYPE]
        : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendErrors: (formName, formData) =>
      dispatch(checkErrorsAction({ formName, formData })),
    deleteInputData: (payload) => dispatch(deleteInputDataAction(payload)),
  };
};

class CreateEventModal extends Component {
  onConfirm = () => {
    if (this.props.selectedType.value.value === "4") {
      this.props.deleteInputData({
        formName: this.props.formName,
        inputName: "createEventWomen",
      });
      this.props.deleteInputData({
        formName: this.props.formName,
        inputName: "createEventMen",
      });
    } else {
      this.props.deleteInputData({
        formName: this.props.formName,
        inputName: "createEventOnlineTotal",
      });
    }
    const result = validateForm(this.props.formData);
    if (result.isValid) {
      let client =
        this.props.selectedType.value.value === "4"
          ? {
              date: this.props.formData.createEventDate.value,
              totalAttendants: this.props.formData.createEventOnlineTotal.value,
              projectId: this.props.formData.createEventProject.value.id,
              eventTypeId: this.props.formData.createEventType.value.value,
            }
          : {
              date: this.props.formData.createEventDate.value,
              maleAttendants: this.props.formData.createEventMen.value,
              projectId: this.props.formData.createEventProject.value.id,
              eventTypeId: this.props.formData.createEventType.value.value,
              femaleAttendants: this.props.formData.createEventWomen.value,
            };
      if (this.props.formData.createEventComment.value) {
        client.comment = this.props.formData.createEventComment.value;
      }
      this.props.onConfirm(client);
      this.onClose();
    } else {
      this.props.sendErrors(this.props.formName, result.formData);
    }
  };

  onClose = () => {
    this.props.deleteInputData({
      formName: this.props.formName,
      inputName: CREATE_EVENT_PROJECT,
    });
    this.props.deleteInputData({
      formName: this.props.formName,
      inputName: CREATE_EVENT_TYPE,
    });
    this.props.deleteInputData({
      formName: this.props.formName,
      inputName: "createEventDate",
    });
    this.props.deleteInputData({
      formName: this.props.formName,
      inputName: "createEventWomen",
    });
    this.props.deleteInputData({
      formName: this.props.formName,
      inputName: "createEventMen",
    });
    this.props.deleteInputData({
      formName: this.props.formName,
      inputName: "createEventComment",
    });
    this.props.deleteInputData({
      formName: this.props.formName,
      inputName: "createEventOnlineTotal",
    });

    this.props.onClose();
  };

  render() {
    var projects = addingParamToObjectsInArray(
      this.props.projects,
      "label",
      "name"
    );
    projects = addingParamToObjectsInArray(projects, "value", "id");
    var selectedType =
      this.props.selectedType.value &&
      this.props.selectedType.value.value === "4"
        ? true
        : false;
    return (
      <Modal
        show={this.props.showModal}
        onHide={this.onClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <BlockUi
          className={"loader"}
          message={"L O A D I N G"}
          tag="div"
          blocking={this.props.blockState}
        >
          <Modal.Header closeButton>
            <Modal.Title id="modal-title">Add new action</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SelectInput
              className="react-select-container"
              labelClassName="modal-input-label"
              options={projects}
              isMulti={false}
              name={CREATE_EVENT_PROJECT}
              formName={this.props.formName}
              label={"Projects"}
              validationTypes={["required"]}
              placeholder="Select project"
              showAllSelected={false}
              defaultValue={[]}
              errorClassName="modal-error-select-input"
              errorMessageClassName="modal-error-message"
            />
            <SelectInput
              className="react-select-container"
              labelClassName="modal-input-label"
              options={EVENT_OPTIONS_COLORED}
              isMulti={false}
              name={CREATE_EVENT_TYPE}
              formName={this.props.formName}
              label={"Type"}
              validationTypes={["required"]}
              placeholder="Select Type"
              showAllSelected={false}
              coloredDot={true}
              defaultValue={[]}
              errorClassName="modal-error-select-input"
              errorMessageClassName="modal-error-message"
            />
            <DatePickerInput
              labelClassName="modal-input-label"
              className="modal-input"
              type="text"
              label="Date"
              placeholder="Event date"
              name={"createEventDate"}
              validationTypes={["required"]}
              formName={this.props.formName}
              errorClassName="modal-error-input"
              errorMessageClassName="modal-error-message"
            />

            {!selectedType ? (
              <React.Fragment>
                <Input
                  labelClassName="modal-input-label"
                  className="modal-input"
                  type="text"
                  label="Women"
                  placeholder="Number of attending women"
                  name={"createEventWomen"}
                  validationTypes={["required"]}
                  formName={this.props.formName}
                  errorClassName="modal-error-input"
                  errorMessageClassName="modal-error-message"
                />
                <Input
                  labelClassName="modal-input-label"
                  className="modal-input"
                  type="text"
                  label="Men"
                  placeholder="Number of attending men"
                  name={"createEventMen"}
                  validationTypes={["required"]}
                  formName={this.props.formName}
                  errorClassName="modal-error-input"
                  errorMessageClassName="modal-error-message"
                />
              </React.Fragment>
            ) : (
              <Input
                labelClassName="modal-input-label"
                className="modal-input"
                type="text"
                label="Total reach"
                placeholder="Total number of people reached"
                name={"createEventOnlineTotal"}
                validationTypes={["required"]}
                formName={this.props.formName}
                errorClassName="modal-error-input"
                errorMessageClassName="modal-error-message"
              />
            )}
            <FieldInput
              labelClassName="modal-input-label"
              className="modal-input"
              type="text"
              label="Comment"
              name={"createEventComment"}
              validationTypes={[]}
              formName={this.props.formName}
              errorClassName="modal-error-input"
              errorMessageClassName="modal-error-message"
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-button-container">
              <button
                className="modal-button-confirm modal-button"
                onClick={this.onConfirm}
              >
                CONFIRM
              </button>
              <button
                className="modal-button-cancel modal-button"
                onClick={this.onClose}
              >
                CANCEL
              </button>
            </div>
          </Modal.Footer>
        </BlockUi>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEventModal);
