import React, { Component } from "react";
import { connect } from "react-redux";
import EmailInput from "../../inputs/email-input/email-input";
import Input from "../../inputs/input/input";
import Modal from "react-bootstrap/Modal";
import {
  checkErrorsAction,
  deleteInputDataAction,
} from "../../../common/actions/form.actions";
import { validateForm } from "../../../common/validate.function";
import { addAdmin } from "../../../common/api/admins.api";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";

const NEW_ADMIN_EMAIL = "newAdminEmail";
const NEW_ADMIN_NAME = "newAdminName";

const mapStateToProps = (state, props) => {
  return {
    formData: state.forms[props.formName],
    newAdminEmal:
      state.forms[props.formName] &&
      state.forms[props.formName][NEW_ADMIN_EMAIL]
        ? state.forms[props.formName][NEW_ADMIN_EMAIL]
        : {},
    newAdminName:
      state.forms[props.formName] && state.forms[props.formName][NEW_ADMIN_NAME]
        ? state.forms[props.formName][NEW_ADMIN_NAME]
        : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendErrors: (formName, formData) =>
      dispatch(checkErrorsAction({ formName, formData })),
    deleteInputData: (payload) => dispatch(deleteInputDataAction(payload)),
    addAdmin: (newAdmin, toggleBlocking) => {
      dispatch(addAdmin(newAdmin, toggleBlocking));
    },
  };
};

class CreateAdmindModal extends Component {
  onConfirm = () => {
    const result = validateForm(this.props.formData);
    if (result.isValid) {
      let newAdmin = {
        email: this.props.formData.newAdminEmail.value,
        name: this.props.formData.newAdminName.value,
      };
      this.props.addAdmin(newAdmin, this.props.toggleBlockUI);
      // toast.success("New Administrator added!", {
      //   containerId: "Success",
      // });
      this.onClose();
    } else {
      this.props.sendErrors(this.props.formName, result.formData);
    }
  };

  onClose = () => {
    this.props.deleteInputData({
      formName: this.props.formName,
      inputName: NEW_ADMIN_EMAIL,
    });
    this.props.deleteInputData({
      formName: this.props.formName,
      inputName: NEW_ADMIN_NAME,
    });
    this.props.onClose();
  };

  render() {
    return (
      <Modal
        show={this.props.showModal}
        onHide={this.onClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <BlockUi
          className={"loader"}
          message={"L O A D I N G"}
          tag="div"
          blocking={this.props.blockState}
        >
          <Modal.Header closeButton>
            <Modal.Title id="modal-title">Create new administrator</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Input
              labelClassName="modal-input-label"
              className="modal-input"
              type="text"
              label="Admin Name"
              placeholder="Login name"
              name={NEW_ADMIN_NAME}
              validationTypes={["required"]}
              formName={this.props.formName}
              errorClassName="modal-error-input"
              errorMessageClassName="modal-error-message"
            />
            <EmailInput
              labelClassName="modal-input-label"
              className="modal-input"
              type="email"
              label="Admin Email Address"
              placeholder="example@email.com"
              name={NEW_ADMIN_EMAIL}
              validationTypes={["required", "email"]}
              formName={this.props.formName}
              errorClassName="modal-error-input"
              errorMessageClassName="modal-error-message"
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-button-container">
              <button
                className="modal-button-confirm modal-button"
                onClick={this.onConfirm}
              >
                CONFIRM
              </button>
              <button
                className="modal-button-cancel modal-button"
                onClick={this.onClose}
              >
                CANCEL
              </button>
            </div>
          </Modal.Footer>
        </BlockUi>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAdmindModal);
