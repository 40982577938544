import { post, get, put, remove } from "./controller.api";
import {
  getAdminsAction,
  getAvalibleYears,
} from "../../common/actions/admin.actions";
import { getSingleAction } from "../../common/actions/form.actions";

export function getAdmin(id, editFormData) {
  return (dispatch) => {
    return get("/api/admins/" + id).then((response) => {
      editFormData.editAdminName.value = response.data.data[0].name;
      dispatch(
        getSingleAction({
          formName: "EDIT_ADMIN_MODAL",
          formData: editFormData,
        })
      );
    });
  };
}

export function getAdmins() {
  return (dispatch) => {
    return get("/api/admins").then((response) => {
      dispatch(getAdminsAction(response.data.data));
    });
  };
}

export function addAdmin(data, toggleBlocking) {
  toggleBlocking();
  return (dispatch) => {
    post("/api/admins", data).then(() => {
      dispatch(getAdmins());
      toggleBlocking();
    });
  };
}

export function editAdmin(id, name, toggleBlocking) {
  toggleBlocking();
  return (dispatch) => {
    put("/api/admins/" + id, { name: name }).then(() => {
      dispatch(getAdmins());
      toggleBlocking();
    });
  };
}

export function getAdminDashboard(name) {
  return (dispatch) => {
    return get("/api/admins/dashboard").then((response) => {
      dispatch(
        getSingleAction({
          formName: name,
          formData: response.data,
        })
      );
    });
  };
}

export function getYears() {
  return (dispatch) => {
    get("/api/Admins/available-years").then((res) => {
      dispatch(getAvalibleYears(res.data));
    });
  };
}

export function deleteAdmin(id, toggleBlocking) {
  toggleBlocking();
  return (dispatch) => {
    remove("/api/clients/" + id).then(() => {
      dispatch(getAdmins());
      toggleBlocking();
    });
  };
}