import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";

class DeleteModal extends Component {
  render() {
    return (
      <Modal
        show={this.props.showModal}
        onHide={this.props.onClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <BlockUi
          className={"loader"}
          message={"L O A D I N G"}
          tag="div"
          blocking={this.props.blockState}
        >
          <Modal.Header closeButton>
            <Modal.Title id="modal-title">{this.props.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="standard-input-label">{this.props.modalBody}</div>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-button-container">
              <button
                className="modal-button-confirm modal-button"
                onClick={this.props.onConfirm}
              >
                CONFIRM
              </button>
              <button
                className="modal-button-cancel modal-button"
                onClick={this.props.onClose}
              >
                CANCEL
              </button>
            </div>
          </Modal.Footer>
        </BlockUi>
      </Modal>
    );
  }
}

export default DeleteModal;
