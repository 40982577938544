import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reducer from "./common/reducers/main.reducer";
import logger from "redux-logger";
import thunk from "redux-thunk";

const store = formStore();

function formStore() {
  if (process.env.REACT_APP_ENVIROMENT === "production") {
    return createStore(reducer, applyMiddleware(thunk));
  } else {
    return createStore(reducer, applyMiddleware(thunk, logger));
  }
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
