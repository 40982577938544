import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createForm } from "../../common/utility.functions";
import { checkErrorsAction } from "../../common/actions/form.actions";
import {
  createModalAction,
  toggleModalAction,
} from "../../common/actions/modal.actions";
import CreateProjectModal from "../../components/modals/project-modals/create-project-modal";
import EditProjectModal from "../../components/modals/project-modals/edit-project-modal";
import { BasicTable } from "../../components/table/table";
import { projectStyles } from "../../common/consts";
import {
  getProjects,
  getProject,
  editProject,
} from "../../common/api/projects.api";

const FORM_NAME = "projects";
const CREATE_PROJECT_MODAL = "createProjectModal";
const EDIT_PROJECT_MODAL = "editProjectModal";

const mapStateToProps = (state) => {
  return {
    formData: state.forms[FORM_NAME],
    editFormData: state.forms[EDIT_PROJECT_MODAL],
    showCreateProjectModal:
      state.modals && state.modals[CREATE_PROJECT_MODAL]
        ? state.modals[CREATE_PROJECT_MODAL]
        : false,
    showEditProjectModal:
      state.modals && state.modals[EDIT_PROJECT_MODAL]
        ? state.modals[EDIT_PROJECT_MODAL]
        : false,
    tableData:
      state.projects && state.projects.length > 0 ? state.projects : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createForm: (formData, formName) =>
      createForm(formData, dispatch, formName),
    sendErrors: (formData) =>
      dispatch(checkErrorsAction({ formName: FORM_NAME, formData })),
    createModal: (modalName) => dispatch(createModalAction({ modalName })),
    toggleModal: (modalName) => dispatch(toggleModalAction({ modalName })),
    getProjects: () => {
      dispatch(getProjects());
    },
    getProject: (id, data) => {
      dispatch(getProject(id, data));
    },
    editProject: (data, toggleBlocking) => {
      dispatch(editProject(data, toggleBlocking));
    },
  };
};

class Projects extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.props.createForm(this.props.formData, FORM_NAME);
    this.props.createForm(this.props.formData, EDIT_PROJECT_MODAL);
    this.props.createModal(CREATE_PROJECT_MODAL);
    this.props.createModal(EDIT_PROJECT_MODAL);
    this.props.getProjects();
  }

  columns = [
    {
      name: "Project Name",
      selector: "name",
      minWidth: "80%",
      maxWidth: "80%",
    },
    {
      actions: true,
      minWidth: "20%",
      maxWidth: "20%",
      button: true,
      cell: (row) => (
        <button
          onClick={() => {
            this.onEdit(row);
          }}
          className={"small-button"}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];

  openModal = (name) => {
    this.props.toggleModal(name);
  };

  executeEdit = (data) => {
    this.props.editProject(data, this.props.toggleBlockUI);
  };

  closeModal = (name) => {
    this.props.toggleModal(name);
    this.setState({});
  };

  onEdit = (row) => {
    this.setState({ id: row.id });
    this.openModal(EDIT_PROJECT_MODAL);
    this.props.getProject(row.id, this.props.editFormData);
  };

  render() {
    return (
      <div className="full-page-body">
        <div className="body">
          <div className="card-container">
            <div className="headline-container">
              <div className="headline-title">Projects</div>
              <button
                className="small-button"
                onClick={() => {
                  this.openModal(CREATE_PROJECT_MODAL);
                }}
              >
                New Project
              </button>
            </div>
            <CreateProjectModal
              formName={FORM_NAME}
              modalName={CREATE_PROJECT_MODAL}
              showModal={this.props.showCreateProjectModal}
              onClose={() => {
                this.closeModal(CREATE_PROJECT_MODAL);
              }}
              blockState={this.props.blockState}
              toggleBlockUI={this.props.toggleBlockUI}
            />
            <EditProjectModal
              projectId={this.state.id}
              formName={EDIT_PROJECT_MODAL}
              modalName={EDIT_PROJECT_MODAL}
              showModal={this.props.showEditProjectModal}
              onConfirm={this.executeEdit}
              onClose={() => {
                this.closeModal(EDIT_PROJECT_MODAL);
              }}
              blockState={this.props.blockState}
            />
            <div className="table-container">
              <BasicTable
                customStyles={projectStyles}
                columns={this.columns}
                data={this.props.tableData}
                onEdit={this.onEdit}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Projects)
);
