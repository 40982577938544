import React from "react";
import DatePickerInput from "react-datepicker";
import { connect } from "react-redux";
import {
  addInputDataAction,
  updateInputDataAction,
} from "../../../common/actions/form.actions";
import "react-datepicker/dist/react-datepicker.css";
import "./date-picker-input.scss";
import { validateInput } from "../../../common/validate.function";
import { years, MONTHS } from "../../../common/consts";

const TODAY = new Date();

const mapStateToProps = (state, props) => {
  return {
    formData: state.forms[props.formName],
    input:
      state.forms[props.formName] && state.forms[props.formName][props.name]
        ? state.forms[props.formName][props.name]
        : {},
    parameter:
      state.forms[props.formName] &&
      props.validationParams &&
      state.forms[props.formName][props.validationParams.paramName]
        ? state.forms[props.formName][props.validationParams.paramName]
        : null,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addInputDataToStore: (payload) => dispatch(addInputDataAction(payload)),
    updateInputDataInStore: (payload) =>
      dispatch(updateInputDataAction(payload)),
  };
};

class DatePicker extends React.Component {
  componentDidMount() {
    if (!this.props.input.value) {
      this.props.addInputDataToStore({
        formName: this.props.formName,
        inputName: this.props.name,
        inputData: {
          value: this.props.defaultValue,
          validationTypes: this.props.validationTypes,
          errorMessage: null,
          validationParams: {
            ...this.props.validationParams,
            parameter: this.props.parameter,
          },
        },
      });
    }
  }

  handleChange = (date) => {
    let newValidationParams = {
      ...this.props.validationParams,
      parameter: this.props.parameter,
    };
    let errorMessage = validateInput(
      date,
      this.props.validationTypes,
      newValidationParams
    );
    this.props.updateInputDataInStore({
      formName: this.props.formName,
      inputName: this.props.name,
      inputData: {
        value: date,
        validationTypes: this.props.validationTypes,
        errorMessage: errorMessage ? errorMessage : null,
        validationParams: newValidationParams,
      },
    });
    if (this.props.onChange) {
      this.props.onChange();
    }
  };

  render() {
    return (
      <React.Fragment>
        <label className={this.props.labelClassName || "standard-input-label"}>
          {this.props.label}
        </label>
        <DatePickerInput
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                className={"datepicker-button"}
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                {"<"}
              </button>
              <select
                value={date.getFullYear()}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={MONTHS[date.getMonth()]}
                onChange={({ target: { value } }) =>
                  changeMonth(MONTHS.indexOf(value))
                }
              >
                {MONTHS.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button
                className={"datepicker-button"}
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                {">"}
              </button>
            </div>
          )}
          className={
            this.props.input.errorMessage
              ? this.props.errorClassName || "error-input"
              : this.props.className || "standard-input"
          }
          dateFormat="dd MMM yyyy"
          selected={this.props.input.value || this.props.defaultValue}
          openToDate={this.props.input.value || this.props.defaultValue}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          isClearable
          defaultValue={TODAY}
          name={this.props.name}
          placeholderText={this.props.placeholder}
          onChange={this.handleChange}
          autoComplete="off"
        />
        {this.props.input.errorMessage && (
          <p className={this.props.errorMessageClassName || "error-message"}>
            {this.props.input.errorMessage}
          </p>
        )}
      </React.Fragment>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DatePicker);
