export const GET_CLIENTS = "GET_CLIENTS";
export const GET_CLIENT_BY_ID = "GET_CLIENT_BY_ID";

export const getClientsAction = (payload) => {
  return {
    type: GET_CLIENTS,
    payload,
  };
};

export const getClientByIdAction = (payload) => {
  return {
    type: GET_CLIENT_BY_ID,
    payload,
  };
};
