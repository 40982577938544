import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import logo from "../../images/logo.jpg";

const NotFound = () => (
  <div>
    <div className="logo-container">
      <img className="logo-picture" src={logo} alt="Logo" height="50" />
    </div>
    <div className="session-title">Page not found!</div>
    <div className="session-link-container">
      <Link className="session-link" to="/">
        Go to login
      </Link>
    </div>
  </div>
);

export default withRouter(NotFound);
