import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import EmailInput from "../../inputs/email-input/email-input";
import Input from "../../inputs/input/input";
import SelectInput from "../../inputs/select-input/select-input";
import {
  checkErrorsAction,
  deleteInputDataAction,
} from "../../../common/actions/form.actions";
import { validateForm } from "../../../common/validate.function";
import {
  getDefaultValueForMultiValueSelectInput,
  listingFormPropertyId,
  addingParamToObjectsInArray,
} from "../../../common/utility.functions";
import { getProjects } from "../../../common/api/projects.api";
import { addClient } from "../../../common/api/clients.api";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";

const NEW_CLIENT_EMAIL = "newClientEmal";
const NEW_CLIENT_NAME = "newClientName";
const NEW_CLIENT_PROJECTS = "newClientProjects";

const mapStateToProps = (state, props) => {
  return {
    formData: state.forms[props.formName],
    newClientEmal:
      state.forms[props.formName] &&
      state.forms[props.formName][NEW_CLIENT_EMAIL]
        ? state.forms[props.formName][NEW_CLIENT_EMAIL]
        : {},
    newClientName:
      state.forms[props.formName] &&
      state.forms[props.formName][NEW_CLIENT_NAME]
        ? state.forms[props.formName][NEW_CLIENT_NAME]
        : {},
    newClientProjects:
      state.forms[props.formName] &&
      state.forms[props.formName][NEW_CLIENT_PROJECTS]
        ? state.forms[props.formName][NEW_CLIENT_PROJECTS]
        : {},
    projects: state.projects && state.projects.length > 0 ? state.projects : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendErrors: (formName, formData) =>
      dispatch(checkErrorsAction({ formName, formData })),
    deleteInputData: (payload) => dispatch(deleteInputDataAction(payload)),
    getProjects: () => {
      dispatch(getProjects());
    },
    addClient: (data, toggleBlocking) => {
      dispatch(addClient(data, toggleBlocking));
    },
  };
};

class CreateClientModal extends Component {
  constructor(props) {
    super(props);
    this.props.getProjects();
  }

  onConfirm = () => {
    const result = validateForm(this.props.formData);
    if (result.isValid) {
      let projectIds = listingFormPropertyId(
        this.props.newClientProjects.value
      );
      let data = {
        name: this.props.newClientName.value,
        email: this.props.newClientEmal.value,
        projectsIds: projectIds,
      };
      this.props.addClient(data, this.props.toggleBlockUI);
      // toast.success("New Client added!", {
      //   containerId: "Success",
      // });
      this.onClose();
    } else {
      this.props.sendErrors(this.props.formName, result.formData);
    }
  };

  onClose = () => {
    this.props.deleteInputData({
      formName: this.props.formName,
      inputName: NEW_CLIENT_EMAIL,
    });
    this.props.deleteInputData({
      formName: this.props.formName,
      inputName: NEW_CLIENT_NAME,
    });
    this.props.deleteInputData({
      formName: this.props.formName,
      inputName: NEW_CLIENT_PROJECTS,
    });
    this.props.onClose();
  };

  render() {
    var projects = addingParamToObjectsInArray(
      this.props.projects,
      "label",
      "name"
    );
    projects = addingParamToObjectsInArray(projects, "value", "id");
    return (
      <Modal
        show={this.props.showModal}
        onHide={this.onClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <BlockUi
          className={"loader"}
          message={"L O A D I N G"}
          tag="div"
          blocking={this.props.blockState}
        >
          <Modal.Header closeButton>
            <Modal.Title id="modal-title">Create new client</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Input
              labelClassName="modal-input-label"
              className="modal-input"
              type="text"
              label="Client Name"
              placeholder="Login name"
              name={NEW_CLIENT_NAME}
              validationTypes={["required"]}
              formName={this.props.formName}
              errorClassName="modal-error-input"
              errorMessageClassName="modal-error-message"
            />
            <EmailInput
              labelClassName="modal-input-label"
              className="modal-input"
              type="email"
              label="Client Email Address"
              placeholder="example@email.com"
              name={NEW_CLIENT_EMAIL}
              validationTypes={["required", "email"]}
              formName={this.props.formName}
              errorClassName="modal-error-input"
              errorMessageClassName="modal-error-message"
            />
            <SelectInput
              className="react-select-container"
              labelClassName="modal-input-label"
              options={projects}
              isMulti={true}
              name={NEW_CLIENT_PROJECTS}
              formName={this.props.formName}
              label={"Projects"}
              validationTypes={["required"]}
              placeholder="Select projects"
              showAllSelected={true}
              defaultValue={getDefaultValueForMultiValueSelectInput(
                this.props.projects,
                this.props.currentClientProject
              )}
              errorClassName="modal-error-select-input"
              errorMessageClassName="modal-error-message"
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-button-container">
              <button
                className="modal-button-confirm modal-button"
                onClick={this.onConfirm}
              >
                CONFIRM
              </button>
              <button
                className="modal-button-cancel modal-button"
                onClick={this.onClose}
              >
                CANCEL
              </button>
            </div>
          </Modal.Footer>
        </BlockUi>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateClientModal);
