import React, { Component } from "react";
import { slide as Menu } from "react-burger-menu";
import "./navigation.scss";
import { withRouter } from "react-router-dom";

class NavigationMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }

  jumpTo = (url) => {
    this.props.history.push(url);
    this.closeMenu();
  };

  handleStateChange = (state) => {
    this.setState({ menuOpen: state.isOpen });
  };

  closeMenu = () => {
    this.setState({ menuOpen: false });
  };

  render() {
    return (
      <Menu
        isOpen={this.state.menuOpen}
        onStateChange={(state) => this.handleStateChange(state)}
        pageWrapId={"page-wrap"}
        outerContainerId={"outer-container"}
      >
        <div
          id="home-menu-item"
          className="menu-item-container"
          onClick={() => {
            this.jumpTo("/overview");
          }}
        >
          <span className="menu-nav-arrow"></span>
          <div className="menu-item">Home</div>
        </div>
        <div
          id="admins-menu-item"
          className="menu-item-container"
          onClick={() => {
            this.jumpTo("/admins");
          }}
        >
          <span className="menu-nav-arrow"></span>
          <div className="menu-item">Admins</div>
        </div>
        <div
          id="clients-menu-item"
          className="menu-item-container"
          onClick={() => {
            this.jumpTo("/clients");
          }}
        >
          <span className="menu-nav-arrow"></span>
          <div className="menu-item">Clients</div>
        </div>
        <div
          id="projects-menu-item"
          className="menu-item-container"
          onClick={() => {
            this.jumpTo("/allprojects");
          }}
        >
          <span className="menu-nav-arrow"></span>
          <div className="menu-item">Projects</div>
        </div>
        <div
          id="projects-menu-item"
          className="menu-item-container"
          onClick={() => {
            this.jumpTo("/adminevents");
          }}
        >
          <span className="menu-nav-arrow"></span>
          <div className="menu-item">Events</div>
        </div>
      </Menu>
    );
  }
}

export default withRouter(NavigationMenu);
