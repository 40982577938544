import React from "react";
import DataTable from "react-data-table-component";

const ClientsExpandableComponent = ({ data }) => (
  <div className="expandable-component">
    {data.projects.map((project) => {
      return (
        <div key={project.id} className="table-component-list">
          {project.name}
        </div>
      );
    })}
  </div>
);

const EventsExpandableComponent = ({
  data,
  onEditFunction,
  onDeleteFunction,
}) => (
  <div className="expandable-component">
    <div
      className={
        onEditFunction || onDeleteFunction
          ? "table-comment-short"
          : "table-comment"
      }
    >
      {data.comment}
    </div>
    {onEditFunction || onDeleteFunction ? (
      <div
        className={
          data.comment ? "table-button-field-short" : "table-button-field"
        }
      >
        {onDeleteFunction ? (
          <ExpandButton
            data={data}
            onFunction={onDeleteFunction}
            name={"Delete"}
          />
        ) : null}
        {onEditFunction ? (
          <ExpandButton data={data} onFunction={onEditFunction} name={"Edit"} />
        ) : null}
      </div>
    ) : null}
  </div>
);

const ExpandButton = ({ data, onFunction, name }) => (
  <button
    className={name === "Delete" ? "small-button float-right" : "small-button"}
    onClick={() => {
      onFunction(data);
    }}
  >
    {name}
  </button>
);

export const BasicTable = (props) => {
  return (
    <DataTable
      className="body-adjusted-table"
      columns={props.columns}
      data={props.data}
      highlightOnHover
      dense
      customStyles={props.customStyles ? props.customStyles : {}}
      conditionalRowStyles={
        props.conditionalRowStyles ? props.conditionalRowStyles : []
      }
      responsive
      allowOverflow={false}
      expandableRows={props.expandableRows ? props.expandableRows : false}
      expandOnRowClicked={
        props.expandOnRowClicked ? props.expandableRows : false
      }
      expandableRowsComponent={
        props.expandableRowsComponent ? (
          props.expandableComponent === "events" ? (
            <EventsExpandableComponent
              data={props.data}
              onEditFunction={props.onEditExpand}
              onDeleteFunction={props.onDeleteExpand}
            />
          ) : props.expandableComponent === "clients" ? (
            <ClientsExpandableComponent data={props.data} />
          ) : (
            false
          )
        ) : (
          false
        )
      }
    />
  );
};
