import { GET_PROJECTS, ADD_NEW_PROJECT } from "../actions/project.actions";

const initialState = [];

const projectsReducer = (state = initialState, action) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_PROJECTS: {
      if (action.payload) newState = action.payload;
      return newState;
    }

    case ADD_NEW_PROJECT: {
      newState[action.payload.projects] = {
        ...newState[action.payload.projects],
        ...action.payload.newProject,
      };
      return newState;
    }

    default:
      return state;
  }
};

export default projectsReducer;
