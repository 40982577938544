import {
  GET_EVENTS,
  GET_EVENTS_FOR_ADMIN,
  GET_TOTAL_EVENTS_FOR_ADMIN,
} from "../actions/event.actions";

const initialState = [];

const eventsReducer = (state = initialState, action) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_EVENTS: {
      if (action.payload) newState = action.payload;
      return newState;
    }

    case GET_EVENTS_FOR_ADMIN: {
      if (action.payload) newState = action.payload;
      return newState;
    }

    case GET_TOTAL_EVENTS_FOR_ADMIN: {
      if (action.payload) newState = action.payload;
      return newState;
    }

    default:
      return state;
  }
};

export default eventsReducer;
