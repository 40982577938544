import axios from "axios";
import { BACKEND_API } from "../consts";
import { toast } from "react-toastify";
import { handleErrorStatusCode } from "../utility.functions";

export function get(
  url,
  headers = {
    headers: {
      authorization: "Bearer " + localStorage.getItem("accessToken"),
      "content-type": "application/json",
    },
  }
) {
  return axios
    .get(BACKEND_API + url, headers)
    .catch((error) => {
      handleErrorStatusCode(error.response.status);
    })
    .then((response) => {
      if (response.data.succeeded) {
        toast.success(response.data.message, {
          containerId: "Success",
        });
        return response;
      } else {
        toast.error(response.data.message, {
          containerId: "Error",
        });
        return response;
      }
    });
}

export function post(
  url,
  body,
  headers = {
    headers: {
      authorization: "Bearer " + localStorage.getItem("accessToken"),
      "content-type": "application/json",
    },
  }
) {
  return axios
    .post(BACKEND_API + url, body, headers)
    .catch((error) => {
      handleErrorStatusCode(error.response.status);
    })
    .then((response) => {
      if (response.data.succeeded) {
        toast.success(response.data.message, {
          containerId: "Success",
        });
        return response;
      } else {
        toast.error(response.data.message, {
          containerId: "Error",
        });
        return response;
      }
    });
}

export function put(
  url,
  body,
  headers = {
    headers: {
      authorization: "Bearer " + localStorage.getItem("accessToken"),
      "content-type": "application/json",
    },
  }
) {
  return axios
    .put(BACKEND_API + url, body, headers)
    .catch((error) => {
      handleErrorStatusCode(error.response.status);
    })
    .then((response) => {
      if (response.data.succeeded) {
        toast.success(response.data.message, {
          containerId: "Success",
        });
        return response;
      } else {
        toast.error(response.data.message, {
          containerId: "Error",
        });
        return response;
      }
    });
}

export function remove(
  url,
  headers = {
    headers: {
      authorization: "Bearer " + localStorage.getItem("accessToken"),
      "content-type": "application/json",
    },
  }
) {
  return axios
    .delete(BACKEND_API + url, headers)
    .catch((error) => {
      handleErrorStatusCode(error.response.status);
    })
    .then((response) => {
      if (response.data.succeeded) {
        toast.success(response.data.message, {
          containerId: "Success",
        });
        return response;
      } else {
        toast.error(response.data.message, {
          containerId: "Error",
        });
        return response;
      }
    });
}
