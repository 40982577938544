import { CREATE_MODAL, TOGGLE_MODAL } from "../actions/modal.actions";

const initialState = {};

const modalReducer = (state = initialState, action) => {
   let newState = { ...state };

   switch (action.type) {
      case CREATE_MODAL: {
         newState[action.payload.modalName] = false;
         return newState;
      }

      case TOGGLE_MODAL: {
         newState[action.payload.modalName] = !newState[
            action.payload.modalName
         ];
         return newState;
      }

      default:
         return state;
   }
};

export default modalReducer;
