export const CREATE_FORM = "CREATE_FORM";
export const CLEAR_FORM_DATA = "CLEAR_FORM_DATA";
export const UPDATE_FORM_DATA = "UPDATE_FORM_DATA";
export const ADD_INPUTS_TO_FORM = "ADD_INPUT_TO_FORM";

export const ADD_INPUT_DATA = "ADD_INPUT_DATA";
export const UPDATE_INPUT_DATA = "UPDATE_INPUT_DATA";
export const DELETE_INPUT_DATA = "DELETE_INPUT_DATA";
export const CHECK_ERRORS = "ADD_ERRORS";

export const GET_SINGLE = "GET_SINGLE";

export const createFormAction = (payload) => {
  return {
    type: CREATE_FORM,
    payload,
  };
};

export const clearFormDataAction = (payload) => {
  return {
    type: CLEAR_FORM_DATA,
    payload,
  };
};

export const updateFormDataAction = (payload) => {
  return {
    type: UPDATE_FORM_DATA,
    payload,
  };
};

export const addInputsToFormAction = (payload) => {
  return {
    type: ADD_INPUTS_TO_FORM,
    payload,
  };
};

export const addInputDataAction = (payload) => {
  return {
    type: ADD_INPUT_DATA,
    payload,
  };
};

export const updateInputDataAction = (payload) => {
  return {
    type: UPDATE_INPUT_DATA,
    payload,
  };
};

export const deleteInputDataAction = (payload) => {
  return {
    type: DELETE_INPUT_DATA,
    payload,
  };
};

export const checkErrorsAction = (payload) => {
  return {
    type: CHECK_ERRORS,
    payload,
  };
};

export const getSingleAction = (payload) => {
  return {
    type: GET_SINGLE,
    payload,
  };
};
